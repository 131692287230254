import { useEffect } from 'react'
import { useRef } from 'react'
import { useMemo, useState } from 'react'

import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { useLocation, useParams } from 'react-router-dom'

import { localStorageKey } from '../../../services/constants/localStorageKeys'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode, {
  ILocationState
} from '../../../services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '../../../services/providers/AlterationContext'
import {
  Status,
  useDivergence
} from '../../../services/providers/DivergenceContext'
import {
  InspectionType,
  useInspection
} from '../../../services/providers/InspectionContext'
import { Photo, useRoom, Video } from '../../../services/providers/RoomContext'
import checkLocalStorageKeyAndDo from '../../../services/utils/checkLocalStorageKeyAndDo'
import ArrowRight from '../../assets/svg/ArrowRight.svg'
import CircleInfo from '../../assets/svg/CircleInfo'
import Button from '../../components/Button'
import Carousel from '../../components/Carousel'
import ImageItem from '../../components/Carousel/components/ImageItem'
import VideoItem from '../../components/Carousel/components/VideoItem'
import CircleStatus from '../../components/CircleStatus'
import FullscreenViewer from '../../components/FullscreenViewer'
import Header from '../../components/Header'
import RoomItem from '../../components/RoomItem'

const RoomItemDetailsPage: React.FC = () => {
  const [showFullscreenViewer, setShowFullscreenViewer] = useState(false)
  const [fullscreenViewerIndex, setFullscreenViewerIndex] = useState(0)
  const [fullscreenViewerPhotos, setFullscreenViewerPhotos] = useState<Photo[]>(
    []
  )
  const [fullscreenViewerVideos, setFullscreenViewerVideos] = useState<Video[]>(
    []
  )

  const { state } = useLocation()
  const { alteration } = state as ILocationState

  const { orderCode, roomId, itemId } = useParams()

  const carouselRef = useRef<ResponsiveCarousel>(null)

  const navigate = useNavigateWithOrderCode()
  const { pathname } = useLocation()

  const { divergences, status, startNewDivergence } = useDivergence()
  const { startNewAlteration } = useAlteration()
  const { handlePutVisitedLabelInRoomItem, rooms } = useRoom()
  const { inspection } = useInspection()

  const useDamages =
    inspection?.inspectionType === InspectionType.SAIDA ||
    inspection?.inspectionType === InspectionType.CONFERENCIA

  const room = rooms.find((room) => room?.id === roomId)
  const item = room?.items.find((item) => item?.id === itemId)

  const carouselPhotos = useMemo(() => {
    if (!item?.photos?.length) return []

    return item?.photos.map((photo) => (
      <ImageItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [item?.photos])

  const carouselDamagePhotos = useMemo(() => {
    if (!item?.damagesPhotos?.length) return []

    return item?.damagesPhotos.map((photo) => (
      <ImageItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [item?.damagesPhotos])

  const carouselVideos = useMemo(() => {
    if (!item?.videos?.length) return []

    return item?.videos.map((photo) => (
      <VideoItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [item?.videos])

  const carouselDamageVideos = useMemo(() => {
    if (!item?.damagesVideos?.length) return []

    return item?.damagesVideos.map((photo) => (
      <VideoItem
        key={photo?.filePath}
        filePath={photo?.filePath}
        onMaximizeButtonClick={() => {
          carouselRef?.current &&
            handleOpenFullscreenViewer(
              carouselRef.current?.state?.selectedItem || 0
            )
        }}
      />
    ))
  }, [item?.damagesVideos])

  const handleOpenFullscreenViewer = (index: number) => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.style.filter = 'blur(4px)'
      rootElement.style.position = 'absolute'
      rootElement.style.inset = '0'
    }

    setFullscreenViewerPhotos(
      useDamages ? item?.damagesPhotos || [] : item?.photos || []
    )
    setFullscreenViewerVideos(() => {
      const videos = useDamages ? item?.damagesVideos || [] : item?.videos || []

      return videos.map((video) => ({ ...video, isVideo: true }))
    })
    setFullscreenViewerIndex(index)
    setShowFullscreenViewer(true)
  }

  const handleCloseFullscreenViewer = () => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.style.filter = ''
      rootElement.style.position = ''
      rootElement.style.inset = ''
    }

    setShowFullscreenViewer(false)
  }

  const handleNewDivergence = () => {
    room && item && startNewDivergence(room?.id, item?.id)

    if (orderCode && checkLocalStorageKeyAndDo(localStorageKey(orderCode))) {
      navigate(ROUTES.DIVERGENCE_FORM)

      return
    }

    navigate(ROUTES.DIVERGENCE_TUTORIAL)
  }

  const handleNewAlteration = () => {
    room && item && startNewAlteration(room?.id, item?.id)

    navigate(ROUTES.ALTERATION_FORM)
  }

  const handleGoToDivergences = () => navigate(ROUTES.DIVERGENCE_LIST)

  useEffect(() => {
    carouselRef.current?.moveTo(0)

    return () => {
      handleCloseFullscreenViewer()
    }
  }, [pathname])

  useEffect(() => {
    if (!orderCode || !room?.id || !item?.id) return

    handlePutVisitedLabelInRoomItem(orderCode, room?.id, item?.id)
  }, [orderCode, item?.id, room?.id])

  const carouselContent = useDamages
    ? [...carouselDamageVideos, ...carouselDamagePhotos]
    : [...carouselVideos, ...carouselPhotos]

  const fullscreenViewerContent = [
    ...fullscreenViewerVideos,
    ...fullscreenViewerPhotos
  ]

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.ROOMS}
        title={`${item?.name} | ${room?.name}`}
      />

      <div className="px-4 w-full max-w-[1304px]">
        <div className="flex flex-wrap gap-x-12 justify-between items-start my-6 lg:flex-nowrap">
          <Carousel
            handleCarouselItemClick={handleOpenFullscreenViewer}
            ref={carouselRef}
          >
            {carouselContent}
          </Carousel>

          <section className="flex-1">
            {alteration && (
              <Button
                className="flex justify-between items-center mt-4 mb-8 w-full"
                onClick={handleNewAlteration}
                colored
              >
                <span className="flex-1 text-center">Adicionar alteração</span>
                <svg
                  width="14"
                  height="14"
                  transform="scale(-1 1)"
                  viewBox="0 0 12 20"
                  fill="none"
                >
                  <path
                    d="M11 1L2 10L3 11L11 19"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              </Button>
            )}
            <h3 className="mb-6 text-xl text-gray-500">Observações</h3>

            {useDamages ? (
              item?.damages?.length ? (
                item?.damages?.map((damage, i) => (
                  <div
                    key={i}
                    className="flex flex-wrap gap-2 justify-between items-start mb-4 last-of-type:mb-6 sm:flex-nowrap"
                  >
                    <span className="flex-1 text-gray-500">
                      {damage?.description}
                    </span>

                    {inspection?.inspectionType ===
                      InspectionType.CONFERENCIA && (
                      <div className="flex gap-1 items-center">
                        <span className="text-gray-500">
                          Situação - {!damage?.resolved && 'Não'} Resolvido
                        </span>

                        <CircleStatus
                          color={damage?.resolved ? '#059669' : '#CF0000'}
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <span className="block mb-6 text-gray-500">
                  Nenhuma observação anotada
                </span>
              )
            ) : (
              <span className="block mb-6 text-gray-500">
                {item?.observation || 'Nenhuma observação anotada'}
              </span>
            )}

            {item?.details && (
              <>
                <div className="flex flex-wrap gap-x-2 gap-y-4 justify-between items-center mb-6 sm:flex-nowrap">
                  <h4 className="text-xl text-gray-500">Detalhes</h4>

                  {!useDamages && (
                    <div className="flex gap-1 items-center text-gray-500">
                      <span>Estado - {item?.evaluation?.description}</span>

                      <CircleStatus color={item?.evaluation?.color} />
                    </div>
                  )}
                </div>

                <div className="mb-6">
                  <span className="text-gray-500">
                    {!!item?.details?.length &&
                      item?.details.reduce((acc, item, i) => {
                        const detailsText =
                          acc +
                          (i > 0 ? ' / ' : '') +
                          item?.label +
                          ': ' +
                          item?.value

                        return detailsText
                      }, '')}
                  </span>
                </div>
              </>
            )}

            {inspection?.inspectionType !== 'predial' && (
              <>
                {status === Status.AVAILABLE ? (
                  <>
                    <div className="flex gap-2 items-center">
                      <CircleInfo color="#CF0000" className="shrink-0" />

                      <span className="text-error">
                        Algo diferente? Faça agora uma contestação
                      </span>
                    </div>

                    <Button
                      className="mt-2 mb-6 w-full max-w-sm"
                      styleType="danger"
                      onClick={handleNewDivergence}
                    >
                      Contestar
                    </Button>
                  </>
                ) : status === Status.EXPIRED ? (
                  <div className="mb-4">
                    <div className="flex gap-2 items-center">
                      <CircleInfo color="#CF0000" className="shrink-0" />

                      <span className="text-error">
                        Prazo para contestação expirado
                      </span>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500">
                        Entre em contato com a administradora para habilitar as
                        contestações da vistoria novamente.
                      </span>
                    </div>
                  </div>
                ) : status === Status.UNAVAILABLE ? (
                  <div className="flex gap-2 items-center mb-4">
                    <CircleInfo color="#6b7280" className="shrink-0" />

                    <span className="text-gray-500">
                      Contestação não permitida no momento
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center mb-4">
                    <CircleInfo color="#6b7280" className="shrink-0" />

                    <span className="text-gray-500">
                      Contestação já realizada
                    </span>
                  </div>
                )}

                {!!divergences?.length && (
                  <div
                    onClick={handleGoToDivergences}
                    className="flex gap-x-2 justify-center items-center p-4 w-full text-primary bg-[#FAFAFA] rounded-xl border border-gray-300 shadow-sm cursor-pointer"
                  >
                    <span className="flex-1 text-center">
                      Visualizar contestações
                    </span>

                    <img src={ArrowRight} alt="Ícone Flecha Direita" />
                  </div>
                )}
              </>
            )}
          </section>
        </div>

        <hr />

        <h3 className="mt-6 mb-4 text-lg font-bold text-gray-500">
          Próximos itens em{' '}
          <span className="font-bold text-primary">{room?.name}</span>
        </h3>

        <div className="flex overflow-x-auto gap-6 mb-6 md:flex-wrap">
          {!!room?.items?.length &&
            room.items.map((item) => (
              <RoomItem
                alteration={alteration}
                key={item?.id}
                id={item?.id}
                roomId={room?.id || ''}
                image={
                  useDamages ? item?.damagesPhotos[0]?.filePath : item?.image
                }
                name={item?.name}
                photosQuantity={
                  useDamages
                    ? item?.damagesPhotos?.length || 0
                    : item?.photos?.length || 0
                }
                state={item?.state}
                isCurrentItemInView={item?.id === itemId}
                visitedByUser={!!item?.visitedByUser}
                small
              />
            ))}
        </div>
      </div>

      {showFullscreenViewer && (
        <FullscreenViewer
          startIndex={fullscreenViewerIndex}
          content={fullscreenViewerContent}
          onClose={handleCloseFullscreenViewer}
        />
      )}
    </div>
  )
}

export default RoomItemDetailsPage

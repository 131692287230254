import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import APROVATION_ITEM_STATUS from '../../../services/constants/AprovationItemStatus'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '../../../services/providers/AlterationContext'
import { useRoom } from '../../../services/providers/RoomContext'
import Header from '../../components/Header'
import AlterationStatusItem from './components/AlterationStatusItem'

const AlterationItemDetailsPage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()

  const { alterations } = useAlteration()
  const { rooms } = useRoom()

  const { roomId, itemId } = useParams()

  const room = rooms.find((room) => room?.id === roomId)
  const item = room?.items.find((item) => item?.id === itemId)
  const alterationItem = alterations.find((alteration) => {
    return alteration?.room_id === roomId && alteration?.item_id === itemId
  })

  useEffect(() => {
    if (!alterationItem || !item || !room) navigate(ROUTES.ALTERATION_LIST)
  }, [alterationItem, item, navigate, room])

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.ALTERATION_LIST}
        title={`${item?.name} | ${room?.name}`}
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <span className="block mb-2 w-full font-bold text-left text-gray-500">
          Parecer do responsável
        </span>
        <AlterationStatusItem status={alterationItem?.status || ''} />

        {alterationItem?.status !== APROVATION_ITEM_STATUS.PENDING && (
          <>
            <span className="block mb-2 w-full font-bold text-left text-gray-500">
              Será preciso reverter as alterações ao final da locação?
            </span>
            <span className="mb-6 w-full text-sm text-left text-gray-500">
              {alterationItem?.revert_changes ? 'Sim' : 'Não'}
            </span>

            <span className="block mb-2 w-full font-bold text-left text-gray-500">
              Observações do responsável
            </span>
            <span className="mb-6 w-full text-sm text-left text-gray-500">
              {alterationItem?.comment}
            </span>
          </>
        )}

        <p className="my-8 w-full border-b-2"></p>

        <span className="block mb-2 w-full font-bold text-left text-gray-500">
          Item alterado:
        </span>

        <div
          className="flex flex-col mb-6 w-full bg-white rounded-xl border border-gray-400 shadow-md cursor-pointer"
          onClick={() => navigate(`${ROUTES.ROOM_ITEM}/${roomId}/${itemId}`)}
        >
          <div
            className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
            style={{ backgroundImage: `url(${item?.image})` }}
          />

          <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left">
            <span className="text-xl font-semibold text-gray-600">
              {item?.id === 'building'
                ? item?.name
                : `${item?.name} | ${room?.name}`}
            </span>

            {item?.observation && (
              <span className="text-sm text-gray-500">{item?.observation}</span>
            )}
          </div>
        </div>

        <span className="block mb-2 w-full font-bold text-left text-gray-500">
          A alteração já foi realizada?
        </span>
        <p className="mb-6 w-full text-sm text-left text-gray-500">
          {alterationItem?.done ? 'Sim' : 'Não'}
        </p>

        {alterationItem?.description && (
          <>
            <span className="block mb-2 w-full font-bold text-left text-gray-500">
              Detalhes
            </span>

            <p className="mb-6 w-full text-sm text-left text-gray-500">
              {alterationItem.description}
            </p>
          </>
        )}

        {!!alterationItem?.photos?.length && (
          <>
            <span className="block mb-2 w-full text-left text-gray-500">
              Fotos
            </span>

            <div className="flex flex-wrap gap-4 justify-start items-center mb-6 w-full">
              {alterationItem.photos.map((photo) => (
                <div
                  key={photo?.file_path}
                  className={`
                    relative
                    bg-no-repeat
                    bg-center
                    w-[72px]
                    h-[72px]
                    rounded-lg
                    border-[1.5px]
                    border-primary
                    ${photo?.file_path ? 'bg-cover' : 'bg-white'}
                  `}
                  style={{ backgroundImage: `url("${photo?.file_path}")` }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AlterationItemDetailsPage

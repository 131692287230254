import { useRef, useState } from 'react'

import sendEvaluate from '../../../infra/http/sendEvaluate'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import { useInspection } from '../../../services/providers/InspectionContext'
import { useUser } from '../../../services/providers/UserContext'
import CircleInfo from '../../assets/svg/CircleInfo'
import EvaluateBackground from '../../assets/svg/EvaluateBackground.svg'
import Button from '../../components/Button'
import Header from '../../components/Header'
import KnowMoreModal from './components/KnowMorePopup'
import StarRating from './components/StarRating'
import SuccessModal from './components/SuccessModal'

const EvaluatePage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()

  const { user } = useUser()
  const { inspection } = useInspection()

  const commentInputRef = useRef<HTMLTextAreaElement>(null)

  const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [inspector, setInspector] = useState(0)
  const [report, setReport] = useState(0)

  const handleChangeReportRating = (rating: number) => {
    if (rating === report && rating > 1) {
      setReport(rating - 1)

      return
    }

    setReport(rating)
  }

  const handleChangeInspectorRating = (rating: number) => {
    if (rating === inspector && rating > 1) {
      setInspector(rating - 1)

      return
    }

    setInspector(rating)
  }

  const handleConfirmForm = async () => {
    if (!user) {
      navigate(ROUTES.REGISTER_INITIAL)

      return
    }

    if (!inspection) {
      navigate(ROUTES.MAIN)

      return
    }
    const payload = {
      rating: {
        inspector,
        report,
        comment: commentInputRef.current?.value || ''
      },
      creator: {
        name: user.name,
        email: user.email,
        personType: user.personType
      }
    }

    await sendEvaluate(inspection.publicId, payload)

    setShowSuccessModal(true)

    setTimeout(() => {
      navigate(ROUTES.MAIN)
    }, 2000)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title="Avalie sua vistoria" />

      <div className="flex relative flex-col items-center py-2 px-4 sm:w-96 md:w-144">
        <span className="block mb-4 w-full text-xl text-left text-[#72767D]">
          Relatório
        </span>

        <StarRating rating={report} handleChange={handleChangeReportRating} />

        <span className="block mb-4 w-full text-xl text-left text-[#72767D]">
          Vistoriador
        </span>

        <StarRating
          rating={inspector}
          handleChange={handleChangeInspectorRating}
        />

        <div className="mb-4" />

        <span className="flex gap-4 items-center mb-4 w-full text-xl text-left text-[#72767D]">
          Adicionar comentário{' '}
          <span className="text-sm font-medium text-gray-400">(Opcional)</span>
        </span>

        <textarea
          className="p-2 mb-6 w-full min-h-[82px] text-sm rounded-lg border border-gray-300 focus:border-primary focus:outline-none shadow-md"
          placeholder="Descreva sua experiência..."
          ref={commentInputRef}
        />

        <div className="mb-20 w-full text-sm text-left text-[#72767D]">
          Esse campo é destinado exclusivamente para avaliar sua experiência.{' '}
          <span
            onClick={() => setShowKnowMoreModal(true)}
            className="flex items-center mt-2 text-primary cursor-pointer"
          >
            <CircleInfo className="mx-1 cursor-pointer" />

            <span>Saiba mais</span>
          </span>
        </div>

        <Button
          className="mb-4 w-full max-w-[420px]"
          onClick={handleConfirmForm}
        >
          Avaliar
        </Button>

        <img
          className="absolute -right-80 bottom-0 -z-10 max-w-[400px]"
          src={EvaluateBackground}
          alt="Avaliar Vistoria"
        />
      </div>

      {showSuccessModal && <SuccessModal />}

      {showKnowMoreModal && (
        <KnowMoreModal
          inspectionType={inspection?.inspectionType}
          onCancel={() => setShowKnowMoreModal(false)}
        />
      )}
    </div>
  )
}

export default EvaluatePage

export const ArrowLeft = ({ onClick }: { onClick?: () => void }) => (
  <svg
    className="cursor-pointer"
    width="16"
    height="18"
    viewBox="0 0 12 20"
    fill="none"
    onClick={onClick}
  >
    <path d="M11 1L2 10L3 11L11 19" stroke="white" strokeWidth="2" />
  </svg>
)

export const ArrowRight = ({ onClick }: { onClick?: () => void }) => (
  <svg
    className="cursor-pointer"
    width="16"
    height="18"
    transform="scale(-1 1)"
    viewBox="0 0 12 20"
    fill="none"
    onClick={onClick}
  >
    <path d="M11 1L2 10L3 11L11 19" stroke="white" strokeWidth="2" />
  </svg>
)

import FilledStar from '../../../assets/svg/FilledStar.svg'
import EmptyStar from '../../../assets/svg/Star.svg'

interface StarRatingProps {
  rating: number
  handleChange: (rating: number) => void
}

const Star = (fill: boolean) => (fill ? FilledStar : EmptyStar)

const StarRating = ({ rating, handleChange }: StarRatingProps) => (
  <div className="flex justify-around mb-6 w-full">
    <img
      onClick={() => handleChange(1)}
      className="cursor-pointer"
      src={Star(rating >= 1)}
    />
    <img
      onClick={() => handleChange(2)}
      className="cursor-pointer"
      src={Star(rating >= 2)}
    />
    <img
      onClick={() => handleChange(3)}
      className="cursor-pointer"
      src={Star(rating >= 3)}
    />
    <img
      onClick={() => handleChange(4)}
      className="cursor-pointer"
      src={Star(rating >= 4)}
    />
    <img
      onClick={() => handleChange(5)}
      className="cursor-pointer"
      src={Star(rating >= 5)}
    />
  </div>
)

export default StarRating

import MapMiniature from '../../../assets/img/MapMiniature.png'

interface UserAddressProps {
  address?: {
    street: string
    number: string
    neighborhood: string
    city: string
  }
}

const UserAddress: React.FC<UserAddressProps> = ({ address }) => (
  <div className="flex justify-between items-center mb-6">
    <div className="flex gap-2 items-center">
      <img src={MapMiniature} alt="Mapa Endereço" />

      {address && (
        <span className="text-gray-500">
          {address.street}, {address.number}, {address.neighborhood},{' '}
          {address.city}
        </span>
      )}
    </div>
  </div>
)

export default UserAddress

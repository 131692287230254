import { useState } from 'react'

import { useParams } from 'react-router-dom'

import { localStorageKeyAlterationOnboarding } from '../../../services/constants/localStorageKeys'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import Button from '../../components/Button'
import Header from '../../components/Header'

const AlterationOnboardingPage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()
  const { orderCode }: { orderCode?: string } = useParams()

  const [terms, setTerms] = useState(false)
  const [errors, setErrors] = useState<{ terms: string }>()

  const handleToggleTerms = () => setTerms(!terms)

  const handleContinue = () => {
    if (!terms) {
      setErrors({ terms: 'para continuar você precisa aceitar os termos' })

      return
    }

    if (orderCode) {
      localStorage.setItem(
        localStorageKeyAlterationOnboarding(orderCode),
        'true'
      )
    }

    navigate(ROUTES.ROOMS, true)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title="Registrar alterações" />

      <div className="flex flex-col items-center py-2 px-4 sm:w-96 md:w-144">
        <h1 className="mb-12 text-4xl font-bold text-primary">
          Como funciona o Registro de alterações?
        </h1>

        <ul className="block mb-8 ml-4 list-disc text-gray-500">
          <li>
            O registro de alterações pode ser realizado{' '}
            <span className="font-bold text-primary">a qualquer momento</span>;
          </li>
          <br />
          <li>
            Você pode registrar alterações como: Reformas, manutenções e
            alterações de equipamento;
          </li>
          <br />

          <li>
            O registro de alterações no imóveis deve ser realizado afim de
            integrar o histórico do imóvel, e assim,{' '}
            <span className="font-bold text-primary">
              evitar não conformidades
            </span>{' '}
            desnecessárias na vistoria de saída;
          </li>
          <br />
          <li>
            É possível registrar uma ou mais alterações em um único envio. Ao
            final de um registro clique em{' '}
            <span className="font-bold text-primary">Nova alteração</span>;
          </li>
          <br />
          <li>
            Após enviado o registro de alteração,{' '}
            <span className="font-bold text-primary">
              a imobiliária será comunicada
            </span>{' '}
            para análise do registro;
          </li>
        </ul>
        <div className="flex flex-col gap-1 mb-8 w-full">
          <div className="flex gap-2" onClick={handleToggleTerms}>
            <input
              checked={terms}
              type="checkbox"
              className="w-7"
              onChange={() => 'a'}
            />

            <span className="text-sm text-gray-500">
              Ao aceitar os termos de uso, você declara estar de acordo com as
              orientações.
            </span>
          </div>
          {errors?.terms && (
            <span className="text-red-600">{errors.terms}</span>
          )}
        </div>
        <Button className="mb-4 w-full max-w-sm" onClick={handleContinue}>
          Continuar
        </Button>
      </div>
    </div>
  )
}

export default AlterationOnboardingPage

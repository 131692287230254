import { useState } from 'react'

import { useParams } from 'react-router-dom'

import { localStorageKey } from '../../../services/constants/localStorageKeys'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import Button from '../../components/Button'
import Header from '../../components/Header'
import KnowMoreModal from '../RoomsPage/components/KnowMorePopup'

const DivergenceOnboardingPage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()
  const { orderCode }: { orderCode?: string } = useParams()

  const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)
  const [terms, setTerms] = useState(false)
  const [errors, setErrors] = useState<{ terms: string }>()

  const handleToggleTerms = () => setTerms(!terms)

  const handleContinue = () => {
    if (!terms) {
      setErrors({ terms: 'para continuar você precisa aceitar os termos' })

      return
    }

    if (orderCode) {
      localStorage.setItem(localStorageKey(orderCode), 'true')
    }

    navigate(ROUTES.DIVERGENCE_FORM)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title="Apontar contestação" />

      <div className="flex flex-col items-center py-2 px-4 sm:w-96 md:w-144">
        <h1 className="mb-12 text-4xl font-bold text-primary">
          Aqui vão algumas orientações importantes!
        </h1>

        <ul className="block mb-8 ml-4 list-disc text-gray-500">
          <li>
            Certifique-se de que sua observação está sendo{' '}
            <span className="font-bold text-primary">
              registrada no item correto
            </span>
            ;
          </li>
          <br />
          <li>
            Você poderá contestar a vistoria{' '}
            <span className="font-bold text-primary">somente uma vez</span>.
            Registre{' '}
            <span className="font-bold text-primary">
              todos os itens que deseja contestar
            </span>{' '}
            antes de realizar o envio;
          </li>
          <br />
          <li>
            Caso suas observações sobre um item já se encontrem na vistoria, não
            há necessidade de contestá-lo.{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => setShowKnowMoreModal(true)}
            >
              Saiba mais;
            </span>
          </li>
          <br />
          <li>
            <span className="font-bold text-primary">
              Descreva detalhadamente
            </span>{' '}
            e insira fotos do item mostrando sua contestação;
          </li>
          <br />
          <li>
            Após a contestação você receberá um retorno da avaliação da Rede
            Vistorias em{' '}
            <span className="font-bold text-primary">até 5 dias úteis</span>;
          </li>
        </ul>

        <div className="flex flex-col gap-1 mb-8 w-full">
          <div className="flex gap-2" onClick={handleToggleTerms}>
            <input
              checked={terms}
              type="checkbox"
              className="w-7"
              onChange={() => 'a'}
            />

            <span className="text-sm text-gray-500">
              Ao aceitar os termos de uso, você declara estar de acordo com as
              orientações.
            </span>
          </div>
          {errors?.terms && (
            <span className="text-red-600">{errors.terms}</span>
          )}
        </div>

        <Button className="mb-4 w-full max-w-sm" onClick={handleContinue}>
          Continuar
        </Button>
      </div>

      {showKnowMoreModal && (
        <KnowMoreModal onCancel={() => setShowKnowMoreModal(false)} />
      )}
    </div>
  )
}

export default DivergenceOnboardingPage

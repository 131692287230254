import { useEffect, useRef, useState } from 'react'

import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import {
  PhotoWithFilePath,
  Status,
  useDivergence
} from '../../../services/providers/DivergenceContext'
import { useRoom } from '../../../services/providers/RoomContext'
import validateFileType from '../../../services/utils/validateFileType'
import Plus from '../../assets/svg/Plus.svg'
import TrashIcon from '../../assets/svg/TrashIcon.svg'
import Button from '../../components/Button'
import FormImage from '../../components/FormImage'
import Header from '../../components/Header'
import ConfirmDeleteDivergencePopup from './ConfirmDeleteDivergencePopup'

const DivergenceRegisterPage: React.FC = () => {
  const [showConfirmDeleteDivergencePopup, setConfirmDeleteDivergencePopup] =
    useState(false)

  const navigate = useNavigateWithOrderCode()

  const {
    divergences,
    divergenceInProcess,
    makeDivergence,
    removeDivergencePhotoByFilePath,
    removeDivergence,
    status
  } = useDivergence()
  const { rooms } = useRoom()

  const imageInputRef = useRef<HTMLInputElement>(null)

  const [previews, setPreviews] = useState<PhotoWithFilePath[]>(
    divergenceInProcess?.photos || []
  )
  const [description, setDescription] = useState<string>(
    divergenceInProcess?.description || ''
  )
  const [errors, setErrors] = useState<{ [key: string]: string }>()

  const room = rooms.find((room) => room.id === divergenceInProcess?.roomId)

  const item = room?.items.find(
    (item) => item.id === divergenceInProcess?.itemId
  )

  const handleVerifyIfFileIsEmpty = (file: File) => {
    if (!file) return true

    return Object.keys(file).length === 0 && file.constructor === Object
  }

  const handleAddImage = () => imageInputRef.current?.click()

  const handleImageAdded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files && files?.length > 0) {
      if (!validateFileType(files[0])) {
        setErrors((state) => ({
          ...state,
          previews: 'A imagem enviada é inválida!'
        }))

        return
      }

      setPreviews((state) => [
        ...state,
        { file: files[0], filePath: '', hasBeenUploaded: false }
      ])
    }
  }

  const handleRemoveImage = (
    indexToRemove: number,
    filePathToRemove?: string
  ) => {
    setPreviews((state) => state.filter((_, index) => index !== indexToRemove))

    filePathToRemove && removeDivergencePhotoByFilePath(filePathToRemove)
  }

  const handleConfirmDivergence = () => {
    setErrors({})

    const actualErrors: { [key: string]: string } = {}

    if (!description) {
      actualErrors.description =
        'É necessário detalhar a contestação para continuar'
    }

    if (previews.length === 0) {
      actualErrors.previews = 'É necessário ao menos uma foto para continuar'
    }

    if (Object.keys(actualErrors).length > 0) {
      setErrors(actualErrors)

      return
    }

    if (room && item) {
      makeDivergence({
        roomId: room?.id,
        itemId: item?.id,
        description,
        photos: previews,
        extra: item
      })

      navigate(ROUTES.DIVERGENCE_LIST)
    }
  }

  const handleRemoveDivergece = () => {
    removeDivergence(room?.id as string, item?.id as string)

    navigate(ROUTES.DIVERGENCE_LIST)
  }

  useEffect(() => {
    if (!room) {
      navigate(ROUTES.MAIN)
    }

    if (!item) {
      navigate(ROUTES.ROOMS)
    }
  }, [item, navigate, room])

  const isDivergenceAlreadyRegistered = divergences.some((divergence) => {
    return divergence?.roomId === room?.id && divergence?.itemId === item?.id
  })

  window.onbeforeunload = function () {
    return 'Você tem certeza que quer sair? Todo progresso de contestações será perdido!'
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        title="Contestar"
        deleteDivergenceButton={
          status === Status.AVAILABLE &&
          isDivergenceAlreadyRegistered && (
            <img
              className="w-5 cursor-pointer"
              src={TrashIcon}
              onClick={() => setConfirmDeleteDivergencePopup(true)}
            />
          )
        }
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <h1 className="mb-6 w-full text-3xl font-bold text-left text-primary">
          Registre aqui sua contestação
        </h1>

        <span className="block mb-2 w-full text-left text-gray-500">
          Item selecionado
        </span>

        <div className="box-border flex flex-col mb-4 w-full bg-white rounded-xl shadow-md">
          <div
            className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
            style={{ backgroundImage: `url(${item?.image})` }}
          />

          <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left rounded-b-xl border border-t-0 border-gray-400">
            <span className="text-xl font-semibold text-gray-600">
              {item?.id === 'building'
                ? item?.name
                : `${item?.name} | ${room?.name}`}
            </span>

            {item?.observation && (
              <span className="text-sm text-gray-500">{item?.observation}</span>
            )}
          </div>
        </div>

        <span className="block mb-2 w-full text-left text-gray-500">
          Descreva detalhadamente sua contestação
        </span>

        <textarea
          className="block p-2 w-full min-h-[122px] text-sm rounded-lg border-[1.5px] border-gray-400 focus:border-primary focus:outline-none shadow-md"
          placeholder="Ex: torneira do banheiro também esta com um problema"
          value={description}
          onChange={(v) => setDescription(v.currentTarget.value)}
        />

        <span className="block mt-1 w-full text-left text-error">
          {errors?.description}
        </span>

        <span className="block mt-4 mb-2 w-full text-left text-gray-500">
          Fotos
        </span>

        <div className="flex flex-wrap gap-4 justify-start items-center w-full">
          <FormImage
            image={
              !handleVerifyIfFileIsEmpty(previews[0]?.file)
                ? previews[0]?.file
                : previews[0]?.filePath
            }
            handleClickWithoutImage={handleAddImage}
            handleClickWithImage={() =>
              handleRemoveImage(0, previews[0]?.filePath)
            }
          />
          <FormImage
            image={
              !handleVerifyIfFileIsEmpty(previews[1]?.file)
                ? previews[1]?.file
                : previews[1]?.filePath
            }
            handleClickWithoutImage={handleAddImage}
            handleClickWithImage={() =>
              handleRemoveImage(1, previews[1]?.filePath)
            }
          />
          <FormImage
            image={
              !handleVerifyIfFileIsEmpty(previews[2]?.file)
                ? previews[2]?.file
                : previews[2]?.filePath
            }
            handleClickWithoutImage={handleAddImage}
            handleClickWithImage={() =>
              handleRemoveImage(2, previews[2]?.filePath)
            }
          />
          {previews.slice(3).map((preview, index) => (
            <FormImage
              key={`image_${index}`}
              image={
                !handleVerifyIfFileIsEmpty(preview?.file)
                  ? preview?.file
                  : preview?.filePath
              }
              handleClickWithoutImage={handleAddImage}
              handleClickWithImage={() =>
                handleRemoveImage(index, preview?.filePath)
              }
            />
          ))}

          {previews?.length < 5 && (
            <div
              onClick={handleAddImage}
              className="flex justify-center items-center w-[72px] h-[72px] bg-white rounded-lg border-[1.5px] border-gray-400 cursor-pointer"
            >
              <img src={Plus} />
            </div>
          )}
        </div>

        <input
          ref={imageInputRef}
          onChange={handleImageAdded}
          type="file"
          accept=".jpg, .jpeg, .bmp, .png, .gif"
          className="hidden"
        />

        <span className="block mt-1 w-full text-left text-error">
          {errors?.previews}
        </span>

        {status === Status.AVAILABLE && (
          <Button
            className="mt-8 mb-4 w-full max-w-sm"
            onClick={handleConfirmDivergence}
          >
            Registrar
          </Button>
        )}
      </div>

      {showConfirmDeleteDivergencePopup && (
        <ConfirmDeleteDivergencePopup
          onCancel={() => setConfirmDeleteDivergencePopup(false)}
          onConfirmButton={() => handleRemoveDivergece()}
        />
      )}
    </div>
  )
}

export default DivergenceRegisterPage

import { useState } from 'react'

import { ROUTES } from '../../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../../services/hooks/useNavigateWithOrderCode'
import {
  Status,
  useDivergence
} from '../../../../services/providers/DivergenceContext'
import ArrowRight from '../../../assets/svg/ArrowRight.svg'
import CircleInfo from '../../../assets/svg/CircleInfo'
import KnowMoreModal from './KnowMorePopup'

const Footer: React.FC = () => {
  const [showKnowMoreModal, setShowKnowMoreModal] = useState(false)

  const navigate = useNavigateWithOrderCode()

  const { divergences, status } = useDivergence()

  const handleGoToDivergences = () => {
    navigate(ROUTES.DIVERGENCE_LIST)
  }

  return (
    <div className="flex flex-col justify-start items-start mb-8 w-full">
      <span className="font-bold text-gray-500">Contestações</span>

      {divergences.length > 0 ? (
        <div
          onClick={handleGoToDivergences}
          className="flex gap-x-2 justify-center items-center p-4 mt-2 w-full max-w-sm text-primary bg-[#FAFAFA] rounded-xl border border-gray-300 shadow-sm cursor-pointer"
        >
          <span className="flex-1 text-center">Visualizar contestações</span>

          <img src={ArrowRight} alt="Ícone Flecha Direita" />
        </div>
      ) : status === Status.EXPIRED ? (
        <div className="mt-2">
          <div className="flex gap-2 items-center ">
            <CircleInfo color="#CF0000" className="shrink-0" />

            <span className="text-error">Prazo para contestação expirado</span>
          </div>
          <div>
            <span className="text-sm text-gray-500">
              Entre em contato com a administradora para habilitar as
              contestações da vistoria novamente.
            </span>
          </div>
        </div>
      ) : (
        <>
          <span className="mt-2 text-gray-500">
            Sem contestações no momento.
          </span>

          <div className="flex gap-2 items-center">
            <CircleInfo
              className="cursor-pointer"
              onClick={() => setShowKnowMoreModal(true)}
            />

            <span
              className="text-primary cursor-pointer"
              onClick={() => setShowKnowMoreModal(true)}
            >
              Saiba mais
            </span>
          </div>
        </>
      )}

      {showKnowMoreModal && (
        <KnowMoreModal onCancel={() => setShowKnowMoreModal(false)} />
      )}
    </div>
  )
}

export default Footer

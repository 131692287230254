import X from '../../assets/svg/X.svg'
import Button from '../../components/Button'

interface ConfirmDivergencesModalProps {
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDivergencesModal = (props: ConfirmDivergencesModalProps) => {
  const { onConfirm, onCancel } = props

  const handleConfirm = () => onConfirm()
  const handleClose = () => onCancel()

  return (
    <div
      onClick={handleClose}
      className="flex fixed inset-0 z-50 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 max-w-[460px] bg-white rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">Tem certeza?</span>

          <img src={X} onClick={handleClose} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        <span className="mb-6 text-gray-700">
          Você poderá realizar este envio{' '}
          <span className="font-bold text-error">somente uma vez</span>. Antes
          de prosseguir você ainda pode voltar e registrar contestações de
          outros itens.
          <br />
          <span className="block mt-4">Deseja continuar?</span>
        </span>

        <Button className="mb-4" onClick={handleConfirm}>
          Prosseguir
        </Button>
        <Button onClick={handleClose} styleType="outline">
          Voltar
        </Button>
      </div>
    </div>
  )
}

export default ConfirmDivergencesModal

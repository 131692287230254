import { ROUTES } from '../../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../../services/hooks/useNavigateWithOrderCode'
import DivergencesImage from '../../../assets/img/Divergences.jpg'
import ArrowRight from '../../../assets/svg/ArrowRight.svg'

const DivergencesCard: React.FC = () => {
  const navigate = useNavigateWithOrderCode()

  const handleClick = () => navigate(ROUTES.DIVERGENCE_LIST)

  return (
    <div
      onClick={handleClick}
      className="flex flex-col w-full h-full rounded-xl shadow-md cursor-pointer md:flex-1"
    >
      <img
        src={DivergencesImage}
        className="object-cover h-44 rounded-t-2xl border border-b-0 border-gray-300 md:h-24"
      />

      <div className="flex justify-between items-center p-4 rounded-lg rounded-t-none border border-t-0 border-gray-300">
        <div className="flex flex-col">
          <span className="text-lg font-bold text-gray-600">Contestações</span>
          <span className="text-sm text-gray-600">
            Veja aqui suas contestações registradas
          </span>
        </div>

        <img src={ArrowRight} />
      </div>
    </div>
  )
}

export default DivergencesCard

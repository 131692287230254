import { createContext, Dispatch, ReactNode, useContext, useState } from 'react'

type User = {
  email: string
  name: string
  personType: string
  doc?: string
  phone?: string
}

type UserContextStore = {
  user: User | null
  setUser: Dispatch<React.SetStateAction<User | null>>
}

const UserContext = createContext<UserContextStore>({} as UserContextStore)

UserContext.displayName = 'UserContext'

type UserProviderProps = {
  children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within an UserProvider')
  }

  return context
}

import { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'

import APROVATION_ITEM_STATUS from '../../../../services/constants/AprovationItemStatus'
import { localStorageKeyAlterationOnboarding } from '../../../../services/constants/localStorageKeys'
import { ROUTES } from '../../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../../services/hooks/useNavigateWithOrderCode'
import {
  Alteration,
  useAlteration
} from '../../../../services/providers/AlterationContext'
import { useRoom } from '../../../../services/providers/RoomContext'
import checkLocalStorageKeyAndDo from '../../../../services/utils/checkLocalStorageKeyAndDo'
import AlterationEmpty from '../../../assets/svg/AlterationEmpty.svg'
import ArrowRight from '../../../assets/svg/ArrowRight.svg'
import CircleInfo from '../../../assets/svg/CircleInfo'
import Button from '../../../components/Button'
import Item from './Item'

const Aprovations: React.FC = () => {
  const navigate = useNavigateWithOrderCode()
  const { orderCode } = useParams()
  const { pendingAlterations } = useAlteration()

  const [itemsWithRoom, setItemsWithRoom] = useState<Alteration[]>([])

  const { alterations } = useAlteration()
  const { rooms } = useRoom()

  const mappedAlterationItems = useMemo(() => {
    if (!itemsWithRoom?.length) return []

    const pendingItems: any[] = []
    itemsWithRoom.map((alteration) => {
      const room = rooms.find((room) => room?.id === alteration?.room_id)
      const item = room?.items.find((item) => item?.id === alteration?.item_id)

      if (alteration.status === APROVATION_ITEM_STATUS.PENDING) {
        pendingItems.push({
          ...alteration,
          room,
          item
        })
      }
    })
    return pendingItems
  }, [itemsWithRoom])

  useEffect(() => {
    setItemsWithRoom(() => {
      return alterations.filter((alteration) => !!alteration?.room_id)
    })
  }, [alterations, rooms])

  const handleNewAlteration = () => {
    if (
      orderCode &&
      checkLocalStorageKeyAndDo(localStorageKeyAlterationOnboarding(orderCode))
    ) {
      navigate(ROUTES.ROOMS, true)

      return
    }
    navigate(ROUTES.ALTERATION_ONBOARDING)
  }

  return (
    <div className="flex flex-col justify-center items-center pb-8 w-full">
      <Button
        className="flex justify-between items-center mt-4 mb-8 w-full max-w-sm"
        onClick={handleNewAlteration}
        colored
      >
        <span className="flex-1 text-center">Nova alteração</span>
        <svg
          width="14"
          height="14"
          transform="scale(-1 1)"
          viewBox="0 0 12 20"
          fill="none"
        >
          <path d="M11 1L2 10L3 11L11 19" stroke="white" strokeWidth="2" />
        </svg>
      </Button>

      {pendingAlterations.length > 0 && (
        <>
          <p className="flex items-center">
            <CircleInfo width={16} height={16} color={'#0170B3'} />
            <span className="ml-1 font-bold text-primary">Atenção</span>: Você
            possui registros não enviados.
          </p>
          <Button
            styleType="outline"
            colored
            className="flex justify-between items-center mt-4 mb-8 w-full max-w-sm"
            onClick={() => navigate(ROUTES.ALTERATION_PENDING_LIST)}
          >
            <span className="flex-1 text-center">Ver registros pendentes</span>
            <img src={ArrowRight} />
          </Button>
        </>
      )}

      {mappedAlterationItems.length > 0 ? (
        <>
          <p className="my-4 w-full text-[18px]  font-bold text-left text-gray-500">
            Alterações enviadas
          </p>
          {mappedAlterationItems.map((alteration, i) => (
            <Item key={i} alteration={alteration}></Item>
          ))}
        </>
      ) : (
        <>
          <p className="my-4 text-gray-500">Não há alterações registradas</p>
          <img src={AlterationEmpty} alt="" />
        </>
      )}
    </div>
  )
}

export default Aprovations

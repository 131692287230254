import { ROUTES } from '../../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../../services/hooks/useNavigateWithOrderCode'
import X from '../../../assets/svg/X.svg'

interface KnowMoreModalProps {
  inspectionType: string
  onCancel: () => void
}

const KnowMoreModal = ({ onCancel, inspectionType }: KnowMoreModalProps) => {
  const handleClose = () => onCancel()

  const navigate = useNavigateWithOrderCode()

  return (
    <div
      onClick={handleClose}
      className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 px-6 max-w-[460px] bg-white rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">Avaliação</span>

          <img src={X} onClick={handleClose} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        <span className="mb-6 text-gray-700">
          A avaliação da vistoria visa coletar informações relevantes para o
          aprimoramento de nosso serviço e da experiência de nossos usuários.
          {inspectionType !== 'predial' && (
            <>
              {' '}
              Caso discorde de alguma informação do relatório de vistoria ainda
              é necessário apontar contestações, as que forem apontadas por esse
              canal não serão consideradas. Em havendo necessidade de registrar
              alguma contestação, consulte o menu de{' '}
              <a
                className="font-bold text-primary underline"
                onClick={() => navigate(ROUTES.DIVERGENCE_LIST)}
              >
                Contestação
              </a>
              .
            </>
          )}
        </span>
      </div>
    </div>
  )
}

export default KnowMoreModal

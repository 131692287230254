import Maximize from '../../../../assets/svg/Maximize.svg'

interface MaximizeButtonProps {
  onClick: () => void
}

const MaximizeButton: React.FC<MaximizeButtonProps> = ({ onClick }) => (
  <img
    className="!absolute !top-4 !right-6 !z-10 !w-6 !h-6"
    src={Maximize}
    alt="Ícone maximizar"
    onClick={onClick}
  />
)

export default MaximizeButton

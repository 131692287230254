import { useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import useNavigateWithOrderCode, {
  ILocationState
} from '../../services/hooks/useNavigateWithOrderCode'
import { useDivergence } from '../../services/providers/DivergenceContext'
import ArrowLeft from '../assets/svg/ArrowLeft.svg'
import Download from '../assets/svg/Download.svg'

interface HeaderProps {
  previousRoute?: string
  title: string
  deleteDivergenceButton?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({
  previousRoute,
  title,
  deleteDivergenceButton
}) => {
  const { reports } = useDivergence()

  const navigate = useNavigateWithOrderCode()

  const { state } = useLocation()
  const { alteration } = state as ILocationState

  const downloadReportPdfLink = useMemo(() => {
    const fullInspectionTypeReport = reports.find(
      (report) => report.type === 'full_inspection'
    )

    if (fullInspectionTypeReport?.file) return fullInspectionTypeReport?.file

    const fullDamagesTypeReport = reports.find(
      (report) => report.type === 'full_damages' && report.tags === null
    )

    return fullDamagesTypeReport?.file
  }, [reports])

  const handleNavigateBack = () => {
    previousRoute ? navigate(previousRoute, alteration) : history.back()
  }

  return (
    <header className="flex justify-center p-8 mb-8 w-full bg-white shadow-md">
      <div className="flex gap-x-4 justify-between items-center w-full max-w-[1304px]">
        <img
          className="cursor-pointer"
          src={ArrowLeft}
          onClick={() => handleNavigateBack()}
        />

        <span className="text-xl text-gray-500 truncate">{title}</span>

        <div className="flex gap-x-4 justify-start items-center">
          {deleteDivergenceButton}

          {downloadReportPdfLink && (
            <a
              className="flex gap-x-2 items-center text-gray-500"
              href={downloadReportPdfLink}
              title="Download do PDF"
              target="_blank"
              rel="noreferrer"
              download
            >
              <img src={Download} alt="Ícone de download" />
            </a>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header

import PlayIcon from '../../../../assets//svg/PlayIcon.svg'
import MaximizeButton from '../MaximizeButton'

interface VideoItemProps {
  filePath: string
  onMaximizeButtonClick: () => void
}

const VideoItem: React.FC<VideoItemProps> = ({
  filePath,
  onMaximizeButtonClick
}) => (
  <div className="relative px-2 cursor-pointer">
    <MaximizeButton onClick={onMaximizeButtonClick} />

    <video
      className="w-full h-[260px] bg-black rounded-2xl blur-[1px] md:h-[460px]"
      src={filePath}
      onClick={() => null}
      controls={false}
      muted
    />

    <img className="absolute inset-0 m-auto w-10 h-10" src={PlayIcon} />
  </div>
)

export default VideoItem

import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { resizePhoto } from '../../infra/files/resizePhoto'
import sendImageToS3andReturnFilepath from '../../infra/http/sendImageToS3'
import { retry } from '../utils/retry'

export type Photo = {
  file_path: string
  has_been_uploaded?: boolean
}

export type PhotoWithFilePath = Photo & {
  file: File
}

export type Alteration = {
  room_id: string | '0'
  room_name: string
  item_id: string | 'building' | 'general'
  item_name: string
  done: boolean
  description?: string
  status?: string
  comment?: string
  revert_changes?: string
  responsible_for_analysis?: string
  updated_on?: string
  photos: PhotoWithFilePath[]
  created_at?: string
  id?: number
}

export interface AlterationContextStore {
  imageAlterationUploadUrl?: string
  setAlterationImageUploadUrl: Dispatch<
    React.SetStateAction<string | undefined>
  >
  orderId: string
  setOrderId: Dispatch<React.SetStateAction<string>>
  propertyId: string
  setPropertyId: Dispatch<React.SetStateAction<string>>
  alterations: Alteration[]
  setAlterations: Dispatch<React.SetStateAction<Alteration[]>>
  pendingAlterations: Alteration[]
  setPendingAlterations: Dispatch<React.SetStateAction<Alteration[]>>
  alterationInProcess?: Alteration
  startNewAlteration: (room_id: string, item_id: string) => void
  makeAlteration: (alteration: Alteration) => Promise<void>
  removeAlterationPhotoByFilePath: (file_path: string) => void
  removeAlteration: (room_id: string, item_id: string) => void
  getStoredPendingAlterationByOrderCode: (initialCode: string) => void
}

const AlterationContext = createContext<AlterationContextStore>(
  {} as AlterationContextStore
)

AlterationContext.displayName = 'AlterationContext'

const alterationsStorageKey = '@rvhotsite:alterations:'

interface AlterationProviderProps {
  children: React.ReactNode
}

export const AlterationProvider = ({ children }: AlterationProviderProps) => {
  const [orderCode, setOrderCode] = useState('')
  const [imageAlterationUploadUrl, setAlterationImageUploadUrl] =
    useState<string>()
  const [alterationInProcess, setAlterationInProcess] = useState<Alteration>()
  const [alterations, setAlterations] = useState<Alteration[]>([])
  const [pendingAlterations, setPendingAlterations] = useState<Alteration[]>([])
  const [orderId, setOrderId] = useState<string>('')
  const [propertyId, setPropertyId] = useState<string>('')

  const getStoredPendingAlterationByOrderCode = useCallback(
    (initialCode: string) => {
      setOrderCode(initialCode)

      const key = alterationsStorageKey + initialCode
      const localStoredData = localStorage.getItem(key)
      const parsedLocalStoredData = JSON.parse(localStoredData || '{}') || null

      if (
        parsedLocalStoredData?.pendingAlterations &&
        parsedLocalStoredData?.pendingAlterations.length
      ) {
        setPendingAlterations((state) => {
          const stateAlterationIdentifiers = new Set(
            state.map((alteration) => {
              return `${alteration.room_id} - ${alteration.item_id}`
            })
          )
          const mergedAlterations = [
            ...state,
            ...parsedLocalStoredData.pendingAlterations.filter(
              (storedAlteration: Alteration) => {
                return !stateAlterationIdentifiers.has(
                  `${storedAlteration.room_id} - ${storedAlteration.item_id}`
                )
              }
            )
          ]

          return mergedAlterations
        })
      }
    },
    []
  )

  useEffect(() => {
    if (!orderCode) return

    const key = alterationsStorageKey + orderCode
    const updatedData = { pendingAlterations }

    localStorage.setItem(key, JSON.stringify(updatedData))
  }, [pendingAlterations, orderCode])

  const startNewAlteration = useCallback(
    (room_id: string, item_id: string) => {
      const alreadyStartedAlteration = pendingAlterations.find((alteration) => {
        return (
          alteration?.room_id === room_id && alteration?.item_id === item_id
        )
      })

      if (alreadyStartedAlteration) {
        setAlterationInProcess(alreadyStartedAlteration)
        return
      }

      setAlterationInProcess({
        room_id,
        room_name: '',
        item_id,
        item_name: '',
        done: false,
        photos: [],
        description: ''
      })
    },
    [pendingAlterations]
  )

  const makeAlteration = useCallback(
    async (newAlteration: Alteration) => {
      setAlterationInProcess(undefined)

      let uploadedPhotos: PhotoWithFilePath[] = []

      if (newAlteration?.photos?.length) {
        uploadedPhotos = await Promise.all(
          newAlteration.photos
            .filter((photo) => !photo?.has_been_uploaded)
            .map(async (photo) => {
              const { resizedPhoto, url } = await resizePhoto(photo?.file)

              return retry(3, async () => {
                const file_path = await sendImageToS3andReturnFilepath(
                  imageAlterationUploadUrl as string,
                  resizedPhoto
                )

                if (url) URL.revokeObjectURL(url)

                return {
                  file_path
                }
              })
            })
        )
      }

      const successfullyUploadedPhotos = uploadedPhotos.filter((photo) => {
        return !!photo?.file_path
      })

      const alterationAlreadyExists = pendingAlterations.some(
        (existingAlteration) => {
          return (
            existingAlteration?.room_id === newAlteration?.room_id &&
            existingAlteration?.item_id === newAlteration?.item_id
          )
        }
      )

      if (!alterationAlreadyExists) {
        setPendingAlterations((state) => [
          ...state,
          { ...newAlteration, photos: successfullyUploadedPhotos }
        ])
        return
      }

      setPendingAlterations((state) => {
        return state.map((existingAlteration) => {
          if (
            existingAlteration?.room_id !== newAlteration?.room_id ||
            existingAlteration?.item_id !== newAlteration?.item_id
          ) {
            return existingAlteration
          }

          return {
            ...existingAlteration,
            ...newAlteration,
            photos: [
              ...existingAlteration.photos,
              ...successfullyUploadedPhotos
            ]
          }
        })
      })
    },
    [pendingAlterations, imageAlterationUploadUrl]
  )

  const removeAlterationPhotoByFilePath = useCallback((file_path: string) => {
    setPendingAlterations((state) => {
      return state.map((alteration) => ({
        ...alteration,
        photos: alteration?.photos.filter((photo) => {
          return photo?.file_path !== file_path
        })
      }))
    })

    setAlterationInProcess((state) => {
      const alterationWithoutPhoto = {
        ...state,
        photos: state?.photos.filter((photo) => {
          return photo?.file_path !== file_path
        })
      } as Alteration

      return alterationWithoutPhoto
    })
  }, [])

  const removeAlteration = useCallback((room_id: string, item_id: string) => {
    setPendingAlterations((state) => {
      const itemToRemove = state.find(
        (obj) => obj.room_id === room_id && obj.item_id === item_id
      )
      return state.filter((alteration) => {
        return alteration !== itemToRemove
      })
    })
  }, [])

  return (
    <AlterationContext.Provider
      value={{
        imageAlterationUploadUrl,
        setAlterationImageUploadUrl,
        alterations,
        setAlterations,
        pendingAlterations,
        setPendingAlterations,
        alterationInProcess,
        startNewAlteration,
        orderId,
        setOrderId,
        propertyId,
        setPropertyId,
        makeAlteration,
        removeAlterationPhotoByFilePath,
        removeAlteration,
        getStoredPendingAlterationByOrderCode
      }}
    >
      {children}
    </AlterationContext.Provider>
  )
}

export const useAlteration = () => {
  const context = useContext(AlterationContext)

  if (!context) {
    throw new Error('useAlteration must be used within an AlterationProvider')
  }

  return context
}

import React, { InputHTMLAttributes } from 'react'

import MaskedInput, { Mask } from 'react-text-mask'

type InputMask = Mask | ((value: string) => Mask)

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  errors: any
  mask?: InputMask
}

const defaultClasses =
  'p-2 pl-3 w-full text-[#495565] rounded-md border border-gray-300 focus-within:outline-none'

const Input: React.FC<InputProps> = ({
  className,
  errors,
  name = '',
  mask,
  type,
  ...props
}) => (
  <div className="flex flex-col gap-1 w-full">
    {!mask ? (
      <input
        id={name}
        type={type || 'text'}
        className={`${defaultClasses} ${className}`}
        {...props}
      />
    ) : (
      <MaskedInput
        id={name}
        type={type || 'text'}
        className={`${defaultClasses} ${className}`}
        guide={false}
        mask={mask}
        {...props}
      />
    )}
    {!!errors[name] && (
      <span className="text-red-600">{String(errors[name])}</span>
    )}
  </div>
)

export default Input

import rvLogo from '../../assets/svg/RVLogo.svg'

const InspectionNotFoundPage: React.FC = () => (
  <section className="flex justify-start items-start p-2 w-full h-full bg-white">
    <div className="mt-24 w-full text-center">
      <img
        className="mx-auto mb-16 w-72"
        src={rvLogo}
        alt="Logo Grupo RV"
        title="Grupo RV"
      />
      <span className="text-xl font-semibold text-primary">
        404
        <br />
        Pedido não encontrado
      </span>
    </div>
  </section>
)

export default InspectionNotFoundPage

import { SVGProps } from 'react'

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string
}

const CircleInfo: React.FC<IconProps> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M6.33333 9L8.11111 10.7778L11.6667 7.22222M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
        stroke={props.color || '#059669'}
        strokeWidth="1.77778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CircleInfo

import { ReactNode, useRef } from 'react'

import BeatLoader from 'react-spinners/BeatLoader'

import { useVideoPlayer } from '../../../../services/hooks/useVideoPlayer'
import DownloadVideoIcon from '../../../assets/svg/DownloadVideoIcon.svg'
import { ArrowLeft, ArrowRight } from '../../../assets/svg/ImageViewerArrows'
import Minimize from '../../../assets/svg/Minimize.svg'
import MuteIcon from '../../../assets/svg/MuteIcon.svg'
import PauseIcon from '../../../assets/svg/PauseIcon.svg'
import PlayIcon from '../../../assets/svg/PlayIcon.svg'
import UnmuteIcon from '../../../assets/svg/UnmuteIcon.svg'
import './styles.css'

interface FullscreenVideoProps {
  addIndex: () => void
  onClose: () => void
  paginationCounter: ReactNode
  subtractIndex: () => void
  videoUrl: string
}

const FullscreenVideo: React.FC<FullscreenVideoProps> = ({
  addIndex,
  onClose,
  paginationCounter,
  subtractIndex,
  videoUrl
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const {
    currentTime,
    duration,
    handleLoadingStart,
    handleLoadingEnd,
    handleTogglePlay,
    handleToggleSound,
    handleVideoProgress,
    handleVideoManualProgress,
    loading,
    muted,
    playing,
    progress
  } = useVideoPlayer(videoRef)

  if (!videoUrl) return null

  return (
    <div
      id="video-player"
      className="group flex relative justify-center items-center rounded-2xl"
      onClick={(event) => event.stopPropagation()}
    >
      <video
        className="block min-h-[210px] bg-black rounded-2xl sm:min-h-[420px] lg:min-h-[738px]"
        src={videoUrl}
        ref={videoRef}
        onTimeUpdate={handleVideoProgress}
        onClick={handleTogglePlay}
        onLoadStart={handleLoadingStart}
        onLoadedData={handleLoadingEnd}
        muted={muted}
        loop
      />

      <img
        className="absolute top-4 right-4 cursor-pointer"
        src={Minimize}
        onClick={onClose}
      />

      <BeatLoader
        className="!block absolute inset-y-1/2 inset-x-0 z-50 m-auto -mt-5 text-center lg:-mt-3"
        color="#ffffff"
        size={50}
        loading={loading}
      />

      <div className="absolute inset-y-1/2 left-4" onClick={subtractIndex}>
        <ArrowLeft />
      </div>

      <div className="absolute inset-y-1/2 right-4" onClick={addIndex}>
        <ArrowRight />
      </div>

      <div className="flex absolute bottom-7 z-50 flex-col items-center px-4 w-full opacity-0 group-hover:opacity-100 transition-opacity ease-in sm:bottom-10">
        <div className="flex gap-x-4 justify-center items-center w-full">
          <button
            className="bg-none border-none outline-none cursor-pointer"
            type="button"
            disabled={loading}
            onClick={handleTogglePlay}
          >
            {!playing ? <img src={PlayIcon} /> : <img src={PauseIcon} />}
          </button>

          <input
            className="w-full h-1 rounded appearance-none cursor-pointer"
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={(event) => !loading && handleVideoManualProgress(event)}
          />

          <button
            className="bg-none border-none outline-none cursor-pointer"
            type="button"
            disabled={loading}
            onClick={handleToggleSound}
          >
            {!muted ? <img src={UnmuteIcon} /> : <img src={MuteIcon} />}
          </button>

          <a href={videoUrl} target="_blank" rel="noreferrer" download>
            <img src={DownloadVideoIcon} />
          </a>
        </div>

        <div className="text-xs text-white">
          {currentTime.minutes}:{currentTime.seconds} / {duration.minutes}:
          {duration.seconds}
        </div>
      </div>

      <div className="flex absolute inset-x-1/2 bottom-2 justify-center text-xs text-white sm:text-base">
        {paginationCounter}
      </div>
    </div>
  )
}

export default FullscreenVideo

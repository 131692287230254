interface CircleStatusProps {
  color: string
}

const CircleStatus: React.FC<CircleStatusProps> = (props) => {
  const { color } = props

  return (
    <div
      style={{ backgroundColor: `${color}` }}
      className={`rounded-full w-4 h-4`}
    />
  )
}

export default CircleStatus

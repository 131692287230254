import axios from 'axios'
import { toast } from 'react-toastify'

import config from '../../config'

const randomName = (extension: string) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  const charactersLength = characters.length

  let result = ''

  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result + extension
}

const sendImageToS3andReturnFilepath = async (
  uploadPath: string,
  image: File
) => {
  const imageExtension = '.' + image.name.split('.').pop()

  const name = randomName(imageExtension)

  const url = `${config.S3_BASE_URL}/${uploadPath}/${name}`

  try {
    await axios.put(url, image, {
      headers: {
        'Content-Type': image.type,
        'x-amz-acl': 'public-read-write'
      }
    })
    return url
  } catch (err) {
    toast.error('Falha ao fazer uoload da imagem ' + image.name)
  }
}

export default sendImageToS3andReturnFilepath

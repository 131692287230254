import SuccessModalIcon from '../../../assets/svg/SuccessModalIcon.svg'

const SuccessModal: React.FC = () => (
  <div className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]">
    <div className="flex flex-col gap-4 items-center p-8 bg-white rounded-lg shadow-md">
      <img src={SuccessModalIcon} className="w-24 h-24" />

      <span className="text-lg font-bold text-[#4F6066]">
        Avaliação enviada!
      </span>
    </div>
  </div>
)

export default SuccessModal

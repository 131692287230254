// import AlterationEmpty from '../../../assets/svg/AlterationEmpty.svg'
import { useEffect, useMemo, useState } from 'react'

import APROVATION_ITEM_STATUS from '../../../../services/constants/AprovationItemStatus'
import {
  Alteration,
  useAlteration
} from '../../../../services/providers/AlterationContext'
import { useRoom } from '../../../../services/providers/RoomContext'
import AlterationEmpty from '../../../assets/svg/AlterationEmpty.svg'
import Item from './Item'

const Aprovations: React.FC = () => {
  const [itemsWithRoom, setItemsWithRoom] = useState<Alteration[]>([])

  const { alterations } = useAlteration()
  const { rooms } = useRoom()

  const mappedAlterationItems = useMemo(() => {
    if (!itemsWithRoom?.length) return []

    const approvedItems: any[] = []
    itemsWithRoom.map((alteration) => {
      const room = rooms.find((room) => room?.id === alteration?.room_id)
      const item = room?.items.find((item) => item?.id === alteration?.item_id)

      if (alteration.status !== APROVATION_ITEM_STATUS.PENDING) {
        approvedItems.push({
          ...alteration,
          room,
          item
        })
      }
    })
    return approvedItems
  }, [itemsWithRoom])

  useEffect(() => {
    setItemsWithRoom(() => {
      return alterations.filter((alteration) => !!alteration?.room_id)
    })
  }, [alterations, rooms])

  return (
    <div className="flex flex-col justify-center items-center pb-8 w-full">
      {mappedAlterationItems.length > 0 ? (
        <>
          <p className="my-4 w-full text-[18px]  font-bold text-left text-gray-500">
            Histórico de alterações
          </p>
          {mappedAlterationItems.map((alteration, i) => (
            <Item key={i} alteration={alteration}></Item>
          ))}
        </>
      ) : (
        <>
          <p className="my-4 text-gray-500">Não há alterações registradas</p>
          <img src={AlterationEmpty} alt="" />
        </>
      )}
    </div>
  )
}

export default Aprovations

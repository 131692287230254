const Footer: React.FC = () => (
  <div className="flex justify-center items-center p-2 w-full text-center bg-white border-t-[1px] border-gray-300 sm:min-h-[56px] md:px-24">
    <span className="text-xs text-primary sm:text-sm">
      &copy; Copyright 2016-{new Date().getFullYear()} - Rede Vistorias
      Franchising S/A - CNPJ: 25.005.259/0001-56
    </span>
  </div>
)

export default Footer

import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import ErrorIcon from '../../assets/svg/ErrorIcon.svg'
import Button from '../../components/Button'
import Header from '../../components/Header'

const DivergenceErrorPage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()

  const handleHomeClick = () => navigate(ROUTES.MAIN)

  const handleSeeDivergenceClick = () => navigate(ROUTES.DIVERGENCE_LIST)

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title="Contestações" />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <img src={ErrorIcon} className="mb-6 w-36" />

        <span className="block mb-2 text-xl font-bold text-center text-error">
          Um erro inesperado ocorreu ao enviar a sua contestação.
        </span>

        <span className="block mb-12 font-bold text-center text-gray-500">
          Tente novamente mais tarde.
        </span>

        <Button
          className="mb-4 w-full max-w-sm"
          onClick={handleSeeDivergenceClick}
        >
          Ver contestações
        </Button>
        <Button
          className="w-full max-w-sm"
          styleType="outline"
          onClick={handleHomeClick}
          colored
        >
          Início
        </Button>
      </div>
    </div>
  )
}

export default DivergenceErrorPage

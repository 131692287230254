import { SVGProps } from 'react'

const Calendar: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9166 20.332C17.6902 20.332 18.4321 20.0247 18.979 19.4778C19.526 18.9308 19.8333 18.1889 19.8333 17.4154C19.8333 16.6418 19.526 15.9 18.979 15.353C18.4321 14.806 17.6902 14.4987 16.9166 14.4987C16.1431 14.4987 15.4012 14.806 14.8543 15.353C14.3073 15.9 14 16.6418 14 17.4154C14 18.1889 14.3073 18.9308 14.8543 19.4778C15.4012 20.0247 16.1431 20.332 16.9166 20.332ZM16.9166 21.4987C17.9996 21.4987 19.0382 21.0685 19.804 20.3027C20.5698 19.5369 21 18.4983 21 17.4154C21 16.3324 20.5698 15.2938 19.804 14.528C19.0382 13.7622 17.9996 13.332 16.9166 13.332C15.8337 13.332 14.7951 13.7622 14.0293 14.528C13.2635 15.2938 12.8333 16.3324 12.8333 17.4154C12.8333 18.4983 13.2635 19.5369 14.0293 20.3027C14.7951 21.0685 15.8337 21.4987 16.9166 21.4987Z"
        fill={props.color || '#72767D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9167 15.375C17.0714 15.375 17.2198 15.4365 17.3291 15.5459C17.4385 15.6553 17.5 15.8036 17.5 15.9583V17.1752L17.9124 17.5876C18.0187 17.6976 18.0775 17.845 18.0761 17.9979C18.0748 18.1508 18.0135 18.2972 17.9053 18.4053C17.7972 18.5135 17.6509 18.5748 17.4979 18.5761C17.345 18.5775 17.1976 18.5187 17.0876 18.4124L16.3333 17.6582V15.9583C16.3333 15.8036 16.3948 15.6553 16.5042 15.5459C16.6136 15.4365 16.762 15.375 16.9167 15.375Z"
        fill={props.color || '#72767D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 10.4167H3.5V11.5833H4.66667V10.4167ZM3.5 9.25C3.19058 9.25 2.89383 9.37292 2.67504 9.59171C2.45625 9.8105 2.33333 10.1072 2.33333 10.4167V11.5833C2.33333 11.8928 2.45625 12.1895 2.67504 12.4083C2.89383 12.6271 3.19058 12.75 3.5 12.75H4.66667C4.97608 12.75 5.27283 12.6271 5.49162 12.4083C5.71042 12.1895 5.83333 11.8928 5.83333 11.5833V10.4167C5.83333 10.1072 5.71042 9.8105 5.49162 9.59171C5.27283 9.37292 4.97608 9.25 4.66667 9.25H3.5ZM9.33333 10.4167H8.16667V11.5833H9.33333V10.4167ZM8.16667 9.25C7.85725 9.25 7.5605 9.37292 7.34171 9.59171C7.12292 9.8105 7 10.1072 7 10.4167V11.5833C7 11.8928 7.12292 12.1895 7.34171 12.4083C7.5605 12.6271 7.85725 12.75 8.16667 12.75H9.33333C9.64275 12.75 9.9395 12.6271 10.1583 12.4083C10.3771 12.1895 10.5 11.8928 10.5 11.5833V10.4167C10.5 10.1072 10.3771 9.8105 10.1583 9.59171C9.9395 9.37292 9.64275 9.25 9.33333 9.25H8.16667ZM14 10.4167H12.8333V11.5833H14V10.4167ZM12.8333 9.25C12.5239 9.25 12.2272 9.37292 12.0084 9.59171C11.7896 9.8105 11.6667 10.1072 11.6667 10.4167V11.5833C11.6667 11.8928 11.7896 12.1895 12.0084 12.4083C12.2272 12.6271 12.5239 12.75 12.8333 12.75H14C14.3094 12.75 14.6062 12.6271 14.825 12.4083C15.0437 12.1895 15.1667 11.8928 15.1667 11.5833V10.4167C15.1667 10.1072 15.0437 9.8105 14.825 9.59171C14.6062 9.37292 14.3094 9.25 14 9.25H12.8333Z"
        fill={props.color || '#72767D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66665 15.0846H3.49998V16.2513H4.66665V15.0846ZM3.49998 13.918C3.19056 13.918 2.89381 14.0409 2.67502 14.2597C2.45623 14.4785 2.33331 14.7752 2.33331 15.0846V16.2513C2.33331 16.5607 2.45623 16.8575 2.67502 17.0763C2.89381 17.2951 3.19056 17.418 3.49998 17.418H4.66665C4.97607 17.418 5.27281 17.2951 5.4916 17.0763C5.7104 16.8575 5.83331 16.5607 5.83331 16.2513V15.0846C5.83331 14.7752 5.7104 14.4785 5.4916 14.2597C5.27281 14.0409 4.97607 13.918 4.66665 13.918H3.49998ZM9.33331 15.0846H8.16665V16.2513H9.33331V15.0846ZM8.16665 13.918C7.85723 13.918 7.56048 14.0409 7.34169 14.2597C7.1229 14.4785 6.99998 14.7752 6.99998 15.0846V16.2513C6.99998 16.5607 7.1229 16.8575 7.34169 17.0763C7.56048 17.2951 7.85723 17.418 8.16665 17.418H9.33331C9.64273 17.418 9.93948 17.2951 10.1583 17.0763C10.3771 16.8575 10.5 16.5607 10.5 16.2513V15.0846C10.5 14.7752 10.3771 14.4785 10.1583 14.2597C9.93948 14.0409 9.64273 13.918 9.33331 13.918H8.16665Z"
        fill={props.color || '#72767D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16667 4C1.16667 3.84529 1.22812 3.69692 1.33752 3.58752C1.44692 3.47812 1.59529 3.41667 1.75 3.41667H4.66667V2.25H1.75C1.28587 2.25 0.840752 2.43437 0.512563 2.76256C0.184375 3.09075 0 3.53587 0 4V18C0 18.4641 0.184375 18.9092 0.512563 19.2374C0.840752 19.5656 1.28587 19.75 1.75 19.75H13.5654C13.3167 19.3937 13.1266 18.9998 13.0025 18.5833H1.75C1.59529 18.5833 1.44692 18.5219 1.33752 18.4125C1.22812 18.3031 1.16667 18.1547 1.16667 18V4ZM16.3333 13.3742C16.7203 13.3188 17.1131 13.3188 17.5 13.3742V4C17.5 3.53587 17.3156 3.09075 16.9874 2.76256C16.6592 2.43437 16.2141 2.25 15.75 2.25H14V3.41667H15.75C15.9047 3.41667 16.0531 3.47812 16.1625 3.58752C16.2719 3.69692 16.3333 3.84529 16.3333 4V13.3742Z"
        fill={props.color || '#72767D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 3.41667H11.9951V2.25H5.83333V3.41667Z"
        fill={props.color || '#72767D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.4987H1.16669V6.33203H17.5V7.4987Z"
        fill={props.color || '#72767D'}
      />
      <path
        d="M3.5 1.08333C3.5 0.928624 3.56146 0.780251 3.67085 0.670854C3.78025 0.561458 3.92862 0.5 4.08333 0.5C4.23804 0.5 4.38642 0.561458 4.49581 0.670854C4.60521 0.780251 4.66667 0.928624 4.66667 1.08333V4C4.66667 4.15471 4.60521 4.30308 4.49581 4.41248C4.38642 4.52188 4.23804 4.58333 4.08333 4.58333C3.92862 4.58333 3.78025 4.52188 3.67085 4.41248C3.56146 4.30308 3.5 4.15471 3.5 4V1.08333ZM11.6667 1.08333C11.6667 0.928624 11.7281 0.780251 11.8375 0.670854C11.9469 0.561458 12.0953 0.5 12.25 0.5C12.4047 0.5 12.5531 0.561458 12.6625 0.670854C12.7719 0.780251 12.8333 0.928624 12.8333 1.08333V4C12.8333 4.15471 12.7719 4.30308 12.6625 4.41248C12.5531 4.52188 12.4047 4.58333 12.25 4.58333C12.0953 4.58333 11.9469 4.52188 11.8375 4.41248C11.7281 4.30308 11.6667 4.15471 11.6667 4V1.08333Z"
        fill={props.color || '#72767D'}
      />
    </svg>
  )
}

export default Calendar

import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { getInspection } from '../../../infra/http/getInspection'
import { getInspectionAlterations } from '../../../infra/http/getInspectionAlterations'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import { useAlteration } from '../../../services/providers/AlterationContext'
import { useBuilding } from '../../../services/providers/BuildingContext'
import { useRoom } from '../../../services/providers/RoomContext'
import NewDivergenceSuccessIcon from '../../assets/svg/NewDivergenceSuccessIcon.svg'
import Button from '../../components/Button'
import Header from '../../components/Header'

const AlterationSuccessPage: React.FC = () => {
  const [isRefetchingInspection, setIsRefetchingInspection] = useState(true)

  const {
    setPendingAlterations,
    setAlterations,
    setAlterationImageUploadUrl,
    setPropertyId,
    setOrderId
  } = useAlteration()

  const { setBuilding } = useBuilding()
  const { setRooms } = useRoom()

  const navigate = useNavigateWithOrderCode()
  const { orderCode } = useParams()

  const handleHomeClick = () => navigate(ROUTES.MAIN)

  const handleSeeAlterationsClick = () => navigate(ROUTES.ALTERATION_LIST)

  const handleGetInspectionData = async () => {
    if (!orderCode) {
      navigate(`/${ROUTES.NOT_FOUND}`)
      setIsRefetchingInspection(false)

      return
    }

    try {
      const inspectionData = await getInspection(orderCode)
      const registeredAlterations = await getInspectionAlterations(
        inspectionData?.orderId
      )

      setBuilding(inspectionData?.building || null)
      setRooms(inspectionData.rooms || [])

      setAlterationImageUploadUrl(inspectionData?.imageUploadUrl || '')
      setAlterations(registeredAlterations)
      setPropertyId(inspectionData?.propertyId || '')
      setOrderId(inspectionData?.orderId || '')
      setPendingAlterations([])
      // getStoredPendingAlterationByOrderCode(orderCode)
    } catch {
      navigate(`/${ROUTES.NOT_FOUND}`)
    } finally {
      setIsRefetchingInspection(false)
    }
  }

  useEffect(() => {
    handleGetInspectionData()
  }, [])

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header title="Registrar alterações" />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[520px]">
        <h1 className="mb-4 w-full text-4xl font-bold text-left text-primary">
          Alterações enviadas!
        </h1>
        <span className="block mb-10 w-full text-sm text-left text-gray-500">
          Os registros realizados foram enviados para aprovação e serão
          adicionados ao histórico do imóvel.
        </span>
        <img
          className="mb-10"
          src={NewDivergenceSuccessIcon}
          alt="Caixa de correio"
        />
        <span className="block mb-12 text-sm text-justify text-gray-500">
          Você pode acompanhar a análise e resposta da sua solicitação no menu{' '}
          <span className="font-bold text-primary">Registrar alteração</span>{' '}
          após a análise do responsável.
        </span>
        <Button
          className="mb-4 w-full max-w-sm"
          onClick={handleSeeAlterationsClick}
          disabled={isRefetchingInspection}
        >
          Ver alterações
        </Button>
        <Button
          className="mb-4 w-full max-w-sm"
          onClick={handleHomeClick}
          styleType="outline"
          disabled={isRefetchingInspection}
          colored
        >
          Voltar ao início
        </Button>
      </div>
    </div>
  )
}

export default AlterationSuccessPage

import React from 'react'

import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import '@material/react-linear-progress/dist/linear-progress.css'

import Router from './services/Router'

import 'tailwindcss/tailwind.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Router />
    <ToastContainer />
  </React.StrictMode>
)

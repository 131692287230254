import dayjs from 'dayjs'

import { ROUTES } from '../../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../../services/hooks/useNavigateWithOrderCode'
import ArrowRight from '../../../assets/svg/ArrowRight.svg'
import Calendar from '../../../assets/svg/Calendar'
// import CircleInfo from '../../../assets/svg/CircleInfo'
import EditIcon from '../../../assets/svg/Edit'
import AlterationStatusItem from './AlterationStatusItem'

const Item = ({ alteration }: any, key: any) => {
  const navigate = useNavigateWithOrderCode()

  const handleGoToRoomItem = (
    roomId: string | undefined,
    itemId: string | undefined
  ) => {
    if (roomId && itemId) {
      navigate(`${ROUTES.ALTERATION_SENT_ITEM}/${roomId}/${itemId}`)
    }
  }

  return (
    <div
      key={key}
      className="flex flex-col mb-4 w-full bg-white rounded-xl shadow-md cursor-pointer"
      onClick={() =>
        handleGoToRoomItem(alteration?.room_id, alteration?.item_id)
      }
    >
      <div
        className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
        style={{
          backgroundImage: `url(${alteration?.item?.image})`
        }}
      />

      <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left rounded-b-xl border border-t-0 border-gray-400">
        <div className="flex gap-y-2 justify-between items-center w-full">
          <span className="text-xl font-semibold text-gray-600">
            {alteration?.item?.id === 'building'
              ? alteration?.item?.name
              : `${alteration?.item?.name} | ${alteration?.room?.name}`}
          </span>

          <img src={ArrowRight} alt="Ícone Flecha Direita" />
        </div>

        <div className="flex gap-6">
          <span className="flex gap-1 items-center text-sm text-gray-500">
            <Calendar width={16} height={16} color={'#858585'} />
            {dayjs(alteration?.created_at).format('DD/MM/YYYY')}
          </span>
          <span className="flex gap-1 items-center text-sm text-gray-500">
            <EditIcon width={16} height={16} color={'#858585'} />
            {alteration?.done ? 'Realizada' : 'Não realizada'}
          </span>
        </div>

        <AlterationStatusItem status={alteration?.status || ''} />
      </div>
    </div>
  )
}

export default Item

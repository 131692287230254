import { FormEvent, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '../../../services/api'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import { useDivergence } from '../../../services/providers/DivergenceContext'
import { useInspection } from '../../../services/providers/InspectionContext'
import { useRoom } from '../../../services/providers/RoomContext'
import { useUser } from '../../../services/providers/UserContext'
import OnboardingFirstPageBackground from '../../assets/img/OnboardingFirstPageBackground.jpg'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { masks } from '../../constants'
import OnboardingBase from '../../containers/OnboardingBase'

type Errors = {
  name?: string
  cpf?: string
  phone?: string
  email?: string
  personType?: string
  terms?: string
}

interface RegisterPageProps {
  complete?: boolean
}

const RegisterPage: React.FC<RegisterPageProps> = ({ complete }) => {
  const navigate = useNavigateWithOrderCode()

  const { divergences } = useDivergence()
  const { rooms } = useRoom()
  const { inspection } = useInspection()
  const { user, setUser } = useUser()

  const [isSending, setIsSending] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [personType, setPersonType] = useState('')
  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')

  const [errors, seErrors] = useState<Errors>({})

  const { orderCode } = useParams()

  useEffect(() => {
    const userOnLocalStorage = sessionStorage.getItem(
      'vistoria_digital_responsible'
    )

    if (userOnLocalStorage) {
      const userOnLocalStorageParsed = JSON.parse(userOnLocalStorage)

      setName(userOnLocalStorageParsed.name)
      setEmail(userOnLocalStorageParsed.email)
      setPersonType(userOnLocalStorageParsed.personType)
      setCpf(userOnLocalStorageParsed.cpf)
      setPhone(userOnLocalStorageParsed.phone)

      return
    }

    if (user) {
      setName(user.name)
      setEmail(user.email)
      setPersonType(user.personType)
    }
  }, [user])

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const newErrors: Errors = {}

    if (!name) {
      newErrors.name = 'Preencha a informação para continuar'
    }

    if (!email) {
      newErrors.email = 'Preencha um e-mail válido para continuar'
    }

    if (!personType) {
      newErrors.personType = 'Selecione a informação para continuar'
    }

    if (complete) {
      if (!cpf) {
        newErrors.cpf = 'Preencha a informação para continuar'
      }

      if (!phone) {
        newErrors.phone = 'Preencha a informação para continuar'
      }
    }

    if (Object.keys(newErrors).length > 0) {
      seErrors(newErrors)

      return
    }

    seErrors({})

    if (name && email && personType) {
      const payload = { name, email, personType, cpf, phone }

      setUser(payload)

      sessionStorage.setItem(
        'vistoria_digital_responsible',
        JSON.stringify(payload)
      )

      if (complete) {
        handleConfirmDivergence()
      } else {
        const responsible = {
          email: payload.email,
          name: payload.name,
          personType: payload.personType
        }
        api.post(`/public/hotsite/orders/${orderCode}/access`, {
          responsible
        })
        navigate(ROUTES.MAIN)
      }
    }
  }

  const handleConfirmDivergence = async () => {
    if (isSending) return

    try {
      setIsSending(true)

      const mappedDivergencesPayload = divergences
        .sort((divergence, nextDivergence) => {
          return (
            parseFloat(divergence?.roomId) - parseFloat(nextDivergence?.roomId)
          )
        })
        .reduce((acc, divergence) => {
          if (divergence?.itemId === 'building') {
            acc['0'] = {
              additionalData: {
                observation: divergence?.extra?.observation,
                damages: divergence?.extra?.damages,
                details: divergence?.extra?.details,
                photos: divergence?.extra?.photos,
                videos: divergence?.extra?.videos || []
              },
              description: divergence?.description,
              photos: divergence?.photos.map((photo) => ({
                filePath: photo?.filePath
              }))
            }

            return acc
          }

          const itemRoom = rooms.find((room) => room?.id === divergence?.roomId)

          const divergenceIdentifierKey =
            divergence?.itemId === 'general'
              ? divergence?.roomId
              : divergence?.itemId

          const name =
            divergence?.itemId === 'general'
              ? itemRoom?.name
              : divergence?.extra?.name

          acc[divergenceIdentifierKey] = {
            additionalData: {
              name,
              observation: divergence?.extra?.observation,
              damages: divergence?.extra?.damages,
              details: divergence?.extra?.details,
              photos: divergence?.extra?.photos,
              videos: divergence?.extra?.videos || []
            },
            description: divergence?.description,
            photos: divergence?.photos.map((photo) => ({
              filePath: photo?.filePath
            }))
          }

          return acc
        }, {} as { [key: string]: any })

      if (!Object.keys(mappedDivergencesPayload).length) {
        toast.warning(
          'Nenhuma contestação registrada! Registre uma contestação para continuar.'
        )
        navigate(ROUTES.DIVERGENCE_LIST)
        return
      }

      const responsible = { cpf, email, name, phone, personType }

      sessionStorage.setItem(
        'vistoria_digital_responsible',
        JSON.stringify(responsible)
      )

      const payload = {
        responsible,
        divergences: { ...mappedDivergencesPayload, inspection }
      }

      await api.post(`/public/hotsite/${orderCode}`, payload)

      navigate(ROUTES.DIVERGENCE_SUCCESS)
    } catch {
      navigate(ROUTES.DIVERGENCE_ERROR)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <OnboardingBase
      showLogo
      Body={
        <form
          className="flex flex-col items-center  sm:w-96 md:w-120"
          autoComplete="on"
          onSubmit={handleSubmitForm}
        >
          {complete && (
            <span className="mb-10 text-[#72767D]">
              Para finalizar a solicitação é necessário preencher um breve
              cadastro para que possamos entrar em contato após nossa análise.
              Finalize o cadastro abaixo:
            </span>
          )}

          <div className="mb-6 w-full">
            <Input
              name="name"
              placeholder="Nome completo"
              value={name}
              onChange={(event) => setName(event.target.value)}
              errors={errors}
            />
          </div>

          {complete && (
            <>
              <div className="mb-6 w-full">
                <Input
                  name="cpf"
                  placeholder={inspection?.country === 'PT' ? 'NIF' : 'CPF'}
                  value={cpf}
                  onChange={(event) => setCpf(event.target.value)}
                  errors={errors}
                  mask={
                    inspection?.country === 'PT'
                      ? masks.CPF_ONLY_PT
                      : masks.CPF_ONLY_MASK
                  }
                />
              </div>

              <div className="mb-6 w-full">
                <Input
                  name="phone"
                  placeholder="Telefone"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  errors={errors}
                  mask={
                    inspection?.country === 'PT'
                      ? masks.CELLPHONE_MASK_PT
                      : masks.CELLPHONE_MASK
                  }
                />
              </div>
            </>
          )}

          <div className="mb-6 w-full">
            <Input
              name="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              errors={errors}
              type="email"
            />
          </div>

          <div className="flex flex-col gap-1 mb-20 w-full">
            <select
              className="p-2 pl-3 w-full text-[#495565] rounded-md border border-gray-300 focus-within:outline-none"
              value={personType}
              onChange={(event) => setPersonType(event.target.value)}
            >
              {inspection?.inspectionType === 'predial' ? (
                <>
                  <option className="hidden text-gray-500" value="" disabled>
                    Síndico, Condômino, Imobiliária/Administrador ou Outro
                  </option>
                  <option value="Síndico">Síndico</option>
                  <option value="Condômino">Condômino</option>
                  <option value="Imobiliária/Administrador">
                    Imobiliária/Administrador
                  </option>
                  <option value="Outro">Outro</option>
                </>
              ) : (
                <>
                  <option className="hidden text-gray-500" value="" disabled>
                    Locador, Locatário ou Imobiliária
                  </option>
                  <option value="Locador">Locador</option>
                  <option value="Locatário">Locatário</option>
                  <option value="Imobiliária">Imobiliária</option>
                  <option value="Outro">Outro</option>
                </>
              )}
            </select>

            {errors?.personType && (
              <span className="text-error">{errors.personType}</span>
            )}
          </div>

          <Button className="mb-4 w-full max-w-sm" type="submit">
            {complete ? 'Finalizar cadastro e enviar' : 'Iniciar'}
          </Button>
        </form>
      }
      BackgroundImage={OnboardingFirstPageBackground}
    />
  )
}

export default RegisterPage

import { useMemo } from 'react'

import Photo from '../assets/svg/Photo.svg'
import RemoveDivergencePhoto from '../assets/svg/RemoveDivergencePhoto.svg'

interface FormImageProps {
  image?: File | string
  handleClickWithoutImage: () => void
  handleClickWithImage: () => void
}

const FormImage: React.FC<FormImageProps> = ({
  image,
  handleClickWithImage,
  handleClickWithoutImage
}) => {
  const previewPhoto = useMemo(() => {
    if (!image) return Photo

    if (typeof image === 'string') return image || Photo

    return URL.createObjectURL(image as File)
  }, [image])

  return (
    <div
      onClick={() => !image && handleClickWithoutImage()}
      className={`bg-no-repeat bg-center w-[72px] h-[72px] rounded-lg border-[1.5px] border-primary cursor-pointer relative ${
        image ? 'bg-cover' : 'bg-white'
      }`}
      style={{ backgroundImage: `url("${previewPhoto}")` }}
    >
      {image && (
        <img
          className="absolute -top-2 -right-2"
          src={RemoveDivergencePhoto}
          alt="Ícone remoção de foto"
          title="Remover foto"
          onClick={() => handleClickWithImage()}
        />
      )}
    </div>
  )
}

export default FormImage

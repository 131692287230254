interface TitleProps {
  inspectionType: string
}
const Title: React.FC<TitleProps> = ({ inspectionType }) => (
  <h1 className="flex flex-col mb-8 w-full text-left">
    <span className="text-4xl text-gray-600">Bem vindo ao</span>
    <span className="-mt-1 text-4xl font-bold text-primary">
      {inspectionType === 'predial'
        ? 'Meu Condomínio digital'
        : 'Meu Imóvel digital'}
    </span>
  </h1>
)

export default Title

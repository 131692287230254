import {
  ChangeEventHandler,
  ReactEventHandler,
  RefObject,
  useEffect,
  useState
} from 'react'

interface VideoPlayerOptions {
  autoPlay?: boolean
}

export const useVideoPlayer = (
  videoRef: RefObject<HTMLVideoElement>,
  options?: VideoPlayerOptions
) => {
  const [playing, setPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [muted, setMuted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleTogglePlay = () => setPlaying((state) => !state)

  const handleToggleSound = () => setMuted((state) => !state)

  const handleOpenFullscreen = () => videoRef?.current?.requestFullscreen()

  const handleVideoProgress: ReactEventHandler<HTMLVideoElement> = () => {
    if (!videoRef?.current) return

    const currentPercentage =
      (videoRef.current.currentTime / videoRef.current.duration) * 100

    setProgress(currentPercentage)
  }

  const handleVideoManualProgress: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (!videoRef?.current) return

    const manualChangedValue = Number(event.target.value)

    videoRef.current.currentTime =
      (videoRef.current.duration / 100) * manualChangedValue

    setProgress(manualChangedValue)
  }

  const handleFormatVideoTime = (time: number) => {
    const result = new Date(Math.round(time) * 1000).toISOString().substr(11, 8)

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2)
    }
  }

  const handleLoadingStart = () => {
    setMuted(false)
    setPlaying(false)
    setProgress(0)
    setLoading(true)
  }

  const handleLoadingEnd = () => {
    setPlaying(options?.autoPlay === false ? false : true)
    setLoading(false)
  }

  const currentTime = handleFormatVideoTime(videoRef?.current?.currentTime || 0)
  const duration = handleFormatVideoTime(videoRef?.current?.duration || 0)

  useEffect(() => {
    playing ? videoRef?.current?.play() : videoRef?.current?.pause()
  }, [playing, videoRef])

  return {
    currentTime,
    duration,
    loading,
    muted,
    playing,
    progress,
    handleLoadingStart,
    handleLoadingEnd,
    handleOpenFullscreen,
    handleTogglePlay,
    handleToggleSound,
    handleVideoProgress,
    handleVideoManualProgress
  }
}

export enum ROUTES {
  MAIN = 'main',
  EVALUATE = 'evaluate',
  ONBOARDING = 'onboarding',
  REGISTER_INITIAL = 'register/initial',
  ROOMS = 'rooms',
  ROOM_ITEM = 'room-item',
  DIVERGENCE = 'new-divergence',
  DIVERGENCE_TUTORIAL = 'new-divergence/tutorial',
  DIVERGENCE_ONBOARDING = 'new-divergence/onboarding',
  DIVERGENCE_FORM = 'new-divergence/form',
  DIVERGENCE_LIST = 'divergence-list',
  REGISTER_COMPLETE = 'register/complete',
  DIVERGENCE_SUCCESS = 'new-divergence/success',
  DIVERGENCE_ERROR = 'new-divergence/error',
  ALTERATION_LIST = 'alteration-list',
  ALTERATION_PENDING_LIST = 'alteration-pending-list',
  ALTERATION_ONBOARDING = 'new-alteration/onboarding',
  ALTERATION_FORM = 'new-alteration/form',
  ALTERATION_ERROR = 'new-alteration/error',
  ALTERATION_SUCCESS = 'new-alteration/success',
  ALTERATION_REGISTER = 'alteration/register',
  ALTERATION_SENT_ITEM = 'alteration/sent-item',
  SENT_ITEM = 'sent-item',
  NOT_FOUND = 'not-found'
}

import { useNavigate, useParams } from 'react-router-dom'

const useNavigateWithOrderCode = () => {
  const navigation = useNavigate()
  const { orderCode } = useParams()

  const navigate = (route: string, alteration = false) => {
    if (orderCode) {
      navigation(`/${orderCode}/${route}`, {
        state: { alteration: alteration }
      })
      return
    }

    navigation(route)
  }

  return navigate
}

export default useNavigateWithOrderCode

export interface ILocationState {
  alteration: boolean
}

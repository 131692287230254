import { useState } from 'react'

import { ROUTES } from '../../../services/constants/routes'
import EditIcon from '../../assets/svg/Edit'
import TimeIcon from '../../assets/svg/Time'
import Header from '../../components/Header'
import Aprovation from './components/Aprovation'
import Historic from './components/Historic'

const AlterationListPage: React.FC = () => {
  const [tabSelected, setTabSelected] = useState('Aprovações')

  const handleSelectTab = (tab: string) => {
    setTabSelected(tab)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header previousRoute={ROUTES.MAIN} title="Registrar alterações" />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        {tabSelected === 'Aprovações' && (
          <>
            <h1 className="mb-6 w-full text-[28px] font-bold text-left text-primary">
              Alterações
            </h1>
            <p className="mb-4 w-full text-left text-gray-500">
              Consulte o retorno da administração e realize o registro de novas
              alterações no imóvel.
            </p>
          </>
        )}
        {tabSelected === 'Histórico' && (
          <>
            <h1 className="mb-6 w-full text-[28px] font-bold text-left text-primary">
              Histórico de alterações
            </h1>
            <p className="mb-4 w-full text-left text-gray-500">
              Consulte os tipos, fotos e detalhes de alterações realizadas no
              imóvel.
            </p>
          </>
        )}

        <nav className="flex flex-wrap gap-6 items-center mb-6 border-b-primary">
          <button
            className={`flex flex-col font-bold justify-center items-center p-2 pb-1 ${
              tabSelected === 'Aprovações'
                ? 'text-primary border-b-4 border-b-primary'
                : 'text-gray-500 border-b-4 border-b-white'
            }`}
            onClick={() => handleSelectTab('Aprovações')}
          >
            <EditIcon
              color={tabSelected === 'Aprovações' ? '#0170B3' : '#858585'}
            />
            Alterações
          </button>
          <button
            className={`flex flex-col font-bold justify-center items-center p-2 pb-1 ${
              tabSelected === 'Histórico'
                ? 'text-primary border-b-4 border-b-primary'
                : 'text-gray-500 border-b-4 border-b-white'
            }`}
            onClick={() => handleSelectTab('Histórico')}
          >
            <TimeIcon
              color={tabSelected === 'Histórico' ? '#0170B3' : '#858585'}
            />
            Histórico
          </button>
        </nav>

        {tabSelected === 'Aprovações' && <Aprovation />}

        {tabSelected === 'Histórico' && <Historic />}
      </div>
    </div>
  )
}

export default AlterationListPage

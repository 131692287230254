import { ROUTES } from '../../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../../services/hooks/useNavigateWithOrderCode'
import EvaluateImage from '../../../assets/img/Evaluate.jpg'
import ArrowRight from '../../../assets/svg/ArrowRight.svg'

const EvaluateCard: React.FC = () => {
  const navigate = useNavigateWithOrderCode()

  const handleClick = () => navigate(ROUTES.EVALUATE)

  return (
    <div
      onClick={handleClick}
      className="flex flex-col w-full h-full rounded-xl shadow-md cursor-pointer md:flex-1"
    >
      <img
        src={EvaluateImage}
        className="object-cover h-44 rounded-t-2xl border border-b-0 border-gray-300 md:h-24"
      />

      <div className="flex gap-1 justify-between items-center p-4 w-full rounded-lg rounded-t-none border border-t-0 border-gray-300">
        <div className="flex flex-col">
          <span className="text-lg font-bold text-gray-600">
            Avalie sua vistoria
          </span>
          <span className="text-sm text-gray-600">
            Nos conte como foi sua experiência com a Rede!
          </span>
        </div>

        <img src={ArrowRight} />
      </div>
    </div>
  )
}

export default EvaluateCard

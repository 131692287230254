import { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'

import { localStorageKeyAlterationOnboarding } from '../../../services/constants/localStorageKeys'
import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode from '../../../services/hooks/useNavigateWithOrderCode'
import {
  Alteration,
  useAlteration
} from '../../../services/providers/AlterationContext'
import { useRoom } from '../../../services/providers/RoomContext'
import checkLocalStorageKeyAndDo from '../../../services/utils/checkLocalStorageKeyAndDo'
import ArrowRight from '../../assets/svg/ArrowRight.svg'
import Button from '../../components/Button'
import Header from '../../components/Header'

const AlterationPendingListPage: React.FC = () => {
  const [itemsWithRoom, setItemsWithRoom] = useState<Alteration[]>([])

  const { pendingAlterations, startNewAlteration } = useAlteration()
  const { rooms } = useRoom()
  const navigate = useNavigateWithOrderCode()
  const { orderCode } = useParams()

  const mappedPendingAlterationItems = useMemo(() => {
    if (!itemsWithRoom?.length) return []

    return itemsWithRoom.map((alteration) => {
      const room = rooms.find((room) => room?.id === alteration?.room_id)
      const item = room?.items.find((item) => item?.id === alteration?.item_id)

      return {
        ...alteration,
        room,
        item
      }
    })
  }, [itemsWithRoom])

  useEffect(() => {
    setItemsWithRoom(() => {
      return pendingAlterations.filter((alteration) => !!alteration?.room_id)
    })
  }, [pendingAlterations, rooms])

  const handleNewAlteration = () => {
    if (
      orderCode &&
      checkLocalStorageKeyAndDo(localStorageKeyAlterationOnboarding(orderCode))
    ) {
      navigate(ROUTES.ROOMS, true)

      return
    }
    navigate(ROUTES.ALTERATION_ONBOARDING)
  }

  const handleGoToRoomItem = (room_id: string, item_id: string) => {
    startNewAlteration(room_id, item_id)
    navigate(ROUTES.ALTERATION_FORM)
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.ALTERATION_LIST}
        title="Solicitações registradas"
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        {mappedPendingAlterationItems.map((alterationItem) => (
          <div
            key={`${alterationItem.room_id}-${alterationItem.item_id}`}
            className="flex flex-col mb-4 w-full bg-white rounded-xl shadow-md cursor-pointer"
            onClick={() =>
              handleGoToRoomItem(
                alterationItem?.room_id,
                alterationItem?.item_id
              )
            }
          >
            <div
              className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
              style={{
                backgroundImage: `url(https://painel.dev.redevistorias.com.br/api/medias/75300A80-2E79-492F-8B3F-4B6239D1319F/75300A80-2E79-492F-8B3F-4B6239D1319F/100D9E1C-4F4B-11EB-B7C7-0242AC120004/Photos/4528515D-56C7-5231-9742-144DF49F8607.jpg)`
              }}
            />

            <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left rounded-b-xl border border-t-0 border-gray-400">
              <div className="flex gap-y-2 justify-between items-center w-full">
                <span className="text-xl font-semibold text-gray-600">
                  {alterationItem?.item?.id === 'building'
                    ? alterationItem?.item?.name
                    : `${alterationItem?.item?.name} | ${alterationItem?.room?.name}`}
                </span>

                <img src={ArrowRight} alt="Ícone Flecha Direita" />
              </div>

              <span className="text-sm text-gray-500">
                {alterationItem?.description}
              </span>
            </div>
          </div>
        ))}
        <Button
          className="flex justify-between items-center mt-4 mb-8 w-full max-w-sm"
          onClick={handleNewAlteration}
          colored
        >
          <span className="flex-1 text-center">Registrar nova alteração</span>
          <svg
            width="14"
            height="14"
            transform="scale(-1 1)"
            viewBox="0 0 12 20"
            fill="none"
          >
            <path d="M11 1L2 10L3 11L11 19" stroke="white" strokeWidth="2" />
          </svg>
        </Button>

        {mappedPendingAlterationItems.length > 0 && (
          <Button
            className="mt-8 mb-4 w-full max-w-sm"
            onClick={() => navigate(ROUTES.ALTERATION_REGISTER)}
          >
            Enviar solicitações
          </Button>
        )}
      </div>
    </div>
  )
}

export default AlterationPendingListPage

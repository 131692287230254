import React, { forwardRef, ReactChild } from 'react'

import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'

import { useResolution } from '../../../services/hooks/useResolution'
import ArrowLeftBlue from '../../assets/svg/ArrowLeftBlue.svg'
import ArrowRightBlue from '../../assets/svg/ArrowRightBlue.svg'

import './styles.css'

interface CarouselProps {
  children: ReactChild[]
  handleCarouselItemClick: (index: number) => void
}

const Carousel: React.ForwardRefRenderFunction<
  ResponsiveCarousel,
  CarouselProps
> = ({ children, handleCarouselItemClick }, forwardRef) => {
  const { isSmallDesktop } = useResolution()

  return (
    <ResponsiveCarousel
      statusFormatter={(currentSlide, total) => `${currentSlide} / ${total}`}
      renderIndicator={() => null}
      renderThumbs={() => []}
      onClickItem={handleCarouselItemClick}
      ref={forwardRef}
      width={isSmallDesktop ? '100%' : '680px'}
      renderArrowPrev={(onClickHandler) => (
        <button
          className="absolute bottom-[6px] left-[calc(50%-52px)] z-10 cursor-pointer"
          type="button"
          title="Próximo"
          onClick={onClickHandler}
        >
          <img src={ArrowLeftBlue} alt="Ícone Flecha Esquerda" />
        </button>
      )}
      renderArrowNext={(onClickHandler) => (
        <button
          className="absolute right-[calc(50%-52px)] bottom-[6px] z-10 cursor-pointer"
          type="button"
          title="Próximo"
          onClick={onClickHandler}
        >
          <img src={ArrowRightBlue} alt="Ícone Flecha Direita" />
        </button>
      )}
      emulateTouch
      showStatus
      showArrows
    >
      {children}
    </ResponsiveCarousel>
  )
}

export default forwardRef(Carousel)

import { InspectionType } from '../../services/providers/InspectionContext'

const inspectionTypeToUIMapper = {
  '5asec': '5àsec',
  acompanhamentoObraMM: 'Medição de Obra - MM',
  aditivo: 'Vistoria de Aditivo',
  auditoriaLoja: 'Auditoria de Loja',
  avaliacao: 'Vistoria de Avaliação',
  avaliacaoHousi: 'Visita Técnica Housi',
  cashme: 'CashMe',
  cautelar: 'Cautelar',
  cautelarAterpa: 'Cautelar - ATERPA',
  cautelarQueirozGalvao: 'Cautelar - ENGETEC',
  cautelarCCInfra: 'Cautelar - CCInfra',
  checklistSamsung: 'Vistoria de Auditoria Samsung',
  conferencia: 'Vistoria de Conferência',
  conferenciaFinalDeObra: 'Conferência de Final de Obra',
  entrada: 'Vistoria de Entrada',
  entradaBHomy: 'Vistoria de Entrada B.Homy',
  entradaLight: 'Vistoria de Entrada Lite',
  entrega: 'Vistoria de Entrega',
  finalDeObra: 'Vistoria de Final de Obra',
  inspecaoPredial: 'Vistoria de Inspeção Predial',
  inventario: 'Vistoria de Inventário',
  orcamento: 'Vistoria de Orçamento',
  predial: 'Predial',
  predialO2: 'Predial O2',
  preVistoriaBHomy: 'Pré-Vistoria B.Homy',
  saida: 'Vistoria de Saída',
  tombamento: 'Vistoria de Tombamento',
  transferencia: 'Vistoria de Transferência',
  vialaser: 'Vialaser',
  visita: 'Visita',
  entradaCurtaTemporada: 'Entrada Curta Temporada',
  saidaCurtaTemporada: 'Saída Curta Temporada'
}

export const inspectionTypeToUI = (type: InspectionType) => {
  return inspectionTypeToUIMapper[type] || type
}

import X from '../../../assets/svg/X.svg'

interface KnowMoreModalProps {
  onCancel: () => void
}

const KnowMoreModal: React.FC<KnowMoreModalProps> = ({ onCancel }) => {
  const handleClose = () => onCancel()

  return (
    <div
      onClick={handleClose}
      className="flex fixed inset-0 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 px-6 max-w-[460px] bg-white rounded-lg"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">Contestação</span>

          <img src={X} onClick={handleClose} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        <span className="mb-1 text-gray-700">
          <span className="font-bold text-primary">
            Contestações são utilizadas para apontar as diferenças
          </span>{' '}
          entre a vistoria e o estado presente do imóvel. <br />
          <span className="font-bold text-primary">
            Caso seja detectado algum detalhe que não conste em seu relatório
          </span>
          , utilize a opção de Contestação nas dependências e itens da vistoria.
        </span>
      </div>
    </div>
  )
}

export default KnowMoreModal

import { useEffect } from 'react'

import ScrollContainer from 'react-indiana-drag-scroll'
import { useLocation } from 'react-router-dom'

import { ROUTES } from '../../../services/constants/routes'
import useNavigateWithOrderCode, {
  ILocationState
} from '../../../services/hooks/useNavigateWithOrderCode'
import { useResolution } from '../../../services/hooks/useResolution'
import {
  Building,
  useBuilding
} from '../../../services/providers/BuildingContext'
import {
  InspectionType,
  useInspection
} from '../../../services/providers/InspectionContext'
import { useRoom } from '../../../services/providers/RoomContext'
import Button from '../../components/Button'
import Header from '../../components/Header'
import RatingPopup from '../../components/RatingPopup'
import RoomItem from '../../components/RoomItem'
import { inspectionTypeToUI } from '../../mappers/InspectionTypeMapper'
import Footer from './components/Footer'

const RoomsPage: React.FC = () => {
  const navigate = useNavigateWithOrderCode()
  const { state } = useLocation()
  const { alteration } = state as ILocationState

  const { setBuilding } = useBuilding()
  const { closeRatingInspectionPopupForever, inspection } = useInspection()
  const { allRoomsHaveBeenSelectedAtLeastOnce, handleSelectRoom, rooms } =
    useRoom()
  const { isSmallDesktop } = useResolution()

  const useDamages =
    inspection?.inspectionType === InspectionType.SAIDA ||
    inspection?.inspectionType === InspectionType.CONFERENCIA

  const handleCallSelectRoom = (roomId: string) => {
    setBuilding((state) => ({ ...state, selected: false } as Building))
    handleSelectRoom(roomId)
  }

  useEffect(() => {
    if (!rooms || rooms?.length === 0) {
      navigate(ROUTES.MAIN)
    }
  }, [navigate, rooms])

  const selectedRoom = rooms.find((room) => room?.selected)

  const roomButtons =
    !!rooms.length &&
    rooms.map((room) => (
      <Button
        key={room?.id}
        styleType={room?.selected ? 'default' : 'outline'}
        onClick={() => handleCallSelectRoom(room?.id)}
      >
        {room?.name}
      </Button>
    ))

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={ROUTES.MAIN}
        title={inspectionTypeToUI(inspection?.inspectionType)}
      />

      <div className="px-4 w-full max-w-[1304px]">
        <span className="block mb-4 text-lg font-bold text-gray-500">
          Ambientes
        </span>

        {isSmallDesktop ? (
          <ScrollContainer
            className="flex overflow-x-auto gap-4 mb-6"
            vertical={false}
          >
            {roomButtons}
          </ScrollContainer>
        ) : (
          <nav className="flex flex-wrap gap-4 items-center mb-6">
            {roomButtons}
          </nav>
        )}

        <h3 className="mb-4 text-lg font-bold text-gray-500">
          Itens em{' '}
          <span className="font-bold text-primary">{selectedRoom?.name}</span>
        </h3>

        <div className="flex overflow-x-auto gap-6 mb-6 md:flex-wrap">
          {!!selectedRoom?.items?.length &&
            selectedRoom.items.map((item) => (
              <RoomItem
                alteration={alteration}
                key={item?.id}
                id={item?.id}
                roomId={selectedRoom?.id}
                image={
                  useDamages ? item?.damagesPhotos[0]?.filePath : item?.image
                }
                name={item?.name}
                photosQuantity={
                  useDamages
                    ? item?.damagesPhotos?.length || 0
                    : item?.photos?.length || 0
                }
                state={item?.state}
                visitedByUser={!!item?.visitedByUser}
                small
              />
            ))}
        </div>

        {inspection?.inspectionType !== 'predial' && <Footer />}
      </div>

      {allRoomsHaveBeenSelectedAtLeastOnce &&
        !inspection?.wasRated &&
        !closeRatingInspectionPopupForever && (
          <RatingPopup redirectRouteAfterSubmit={ROUTES.ROOMS} />
        )}
    </div>
  )
}

export default RoomsPage

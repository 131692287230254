import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react'

export type Address = {
  map: string
  city: string
  name: string
  state: string
  number: string
  street: string
  zipcode: string
  latitude: string
  longitude: string
  complement: string
  neighborhood: string
}

export type Content = {
  name: string
  document: string
  signature: boolean
}

export type People = {
  id: string
  title: string
  content: Content[]
}

export enum InspectionType {
  '5ASEC' = '5asec',
  ACOMPANHAMENTO_OBRA_MM = 'acompanhamentoObraMM',
  ADITIVO = 'aditivo',
  AUDITORIA_LOJA = 'auditoriaLoja',
  AVALIACAO = 'avaliacao',
  AVALIACAO_HOUSI = 'avaliacaoHousi',
  CASHME = 'cashme',
  CAUTELAR = 'cautelar',
  CAUTELAR_ATERPA = 'cautelarAterpa',
  CAUTELAR_QUEIROZ_GALVAO = 'cautelarQueirozGalvao',
  CAUTELAR_CC_INFRA = 'cautelarCCInfra',
  CHECKLIST_SAMSUNG = 'checklistSamsung',
  CONFERENCIA = 'conferencia',
  CONFERENCIA_FINAL_DE_OBRA = 'conferenciaFinalDeObra',
  ENTRADA = 'entrada',
  ENTRADA_B_HOMY = 'entradaBHomy',
  ENTRADA_LIGHT = 'entradaLight',
  ENTREGA = 'entrega',
  FINAL_DE_OBRA = 'finalDeObra',
  INSPECAO_PREDIAL = 'inspecaoPredial',
  INVENTARIO = 'inventario',
  ORCAMENTO = 'orcamento',
  PREDIAL = 'predial',
  PREDIAL_O2 = 'predialO2',
  PRE_VISTORIA_B_HOMY = 'preVistoriaBHomy',
  SAIDA = 'saida',
  TOMBAMENTO = 'tombamento',
  TRANSFERENCIA = 'transferencia',
  VIALASER = 'vialaser',
  VISITA = 'visita',
  ENTRADA_CURTA_TEMPORADA = 'entradaCurtaTemporada',
  SAIDA_CURTA_TEMPORADA = 'saidaCurtaTemporada'
}

export type Inspection = {
  people: People[]
  address: Address
  identifier: string
  displayDate: string
  inspectionType: any
  publicId: string
  country: string
  wasRated: boolean
  canRegisterMaintenances: boolean
}

type InspectionContextStore = {
  closeRatingInspectionPopupForever: boolean
  inspection: Inspection | null
  setCloseRatingInspectionPopupForever: Dispatch<SetStateAction<boolean>>
  setInspection: Dispatch<SetStateAction<Inspection | null>>
  useDamages: boolean
}

const InspectionContext = createContext<InspectionContextStore>(
  {} as InspectionContextStore
)

InspectionContext.displayName = 'InspectionContext'

type InspectionProviderProps = {
  children: ReactNode
}

export const InspectionProvider: React.FC<InspectionProviderProps> = ({
  children
}) => {
  const [inspection, setInspection] = useState<Inspection | null>(null)
  const [
    closeRatingInspectionPopupForever,
    setCloseRatingInspectionPopupForever
  ] = useState(false)

  const useDamages =
    inspection?.inspectionType === InspectionType.SAIDA ||
    inspection?.inspectionType === InspectionType.CONFERENCIA

  return (
    <InspectionContext.Provider
      value={{
        closeRatingInspectionPopupForever,
        inspection,
        setCloseRatingInspectionPopupForever,
        setInspection,
        useDamages
      }}
    >
      {children}
    </InspectionContext.Provider>
  )
}

export const useInspection = () => {
  const context = useContext(InspectionContext)

  if (!context) {
    throw new Error('useInspection must be used within an InspectionProvider')
  }

  return context
}

import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useState
} from 'react'

export type Photo = {
  filePath: string
  originalPhotoFilePath?: string
}

export type Video = {
  filePath: string
  originalVideoFilePath?: string
}

export type Damages = {
  damagePhotos: Photo[]
  damageVideos: Video[]
  description: string
  resolved: boolean
  type: string
  tags: string[]
}
export type Details = {
  label: string
  value: string
}

export type Building = {
  state: boolean
  observation: string
  damages: Damages[]
  photos: Photo[]
  damagesPhotos: Photo[]
  videos: Video[]
  damagesVideos: Video[]
  selected: boolean
  details: Details[]
}

type BuildingContextStore = {
  building: Building | null
  setBuilding: Dispatch<React.SetStateAction<Building | null>>
  handleSelectBuilding: () => void
}

const BuildingContext = createContext<BuildingContextStore>(
  {} as BuildingContextStore
)

BuildingContext.displayName = 'BuildingContext'

type BuildingProviderProps = {
  children: React.ReactNode
}

export const BuildingProvider: React.FC<BuildingProviderProps> = ({
  children
}) => {
  const [building, setBuilding] = useState<Building | null>(null)

  const handleSelectBuilding = useCallback(() => {
    setBuilding((state) => ({ ...state, selected: true } as Building))
  }, [])

  return (
    <BuildingContext.Provider
      value={{
        building,
        setBuilding,
        handleSelectBuilding
      }}
    >
      {children}
    </BuildingContext.Provider>
  )
}

export const useBuilding = () => {
  const context = useContext(BuildingContext)

  if (!context) {
    throw new Error('useBuilding must be used within an BuildingProvider')
  }

  return context
}

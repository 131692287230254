import { ReactNode } from 'react'

import RVLogo from '../assets/svg/RVLogo.svg'

interface OnboardingBaseProps {
  showLogo?: boolean
  Body: ReactNode
  Button?: ReactNode
  BackgroundImage?: string
}

const OnboardingBase: React.FC<OnboardingBaseProps> = ({
  showLogo,
  Body,
  Button,
  BackgroundImage
}) => (
  <div className="flex flex-col gap-4 justify-center items-center py-2 px-4 mt-28 w-full h-full">
    {showLogo && (
      <div className="flex justify-center items-center mb-10">
        <img className="block h-full sm:h-28" src={RVLogo} alt="GrupoRV Logo" />
      </div>
    )}

    {Body}

    {Button}

    {BackgroundImage && (
      <>
        <div className="fade-top" />
        <img
          className="object-cover object-right absolute top-0 left-0 -z-10 w-full h-full sm:object-center"
          src={BackgroundImage}
        />
      </>
    )}
  </div>
)

export default OnboardingBase

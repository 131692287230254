import { useDivergence } from '../../../services/providers/DivergenceContext'
import { useInspection } from '../../../services/providers/InspectionContext'
import AlterationCard from './components/AlterationCard'
import DivergencesCard from './components/DivergencesCard'
import EvaluateCard from './components/EvaluateCard'
import InspectionCard from './components/InspectionCard'
import Title from './components/Title'
import UserAddress from './components/UserAddress'

const MainPage: React.FC = () => {
  const { divergences } = useDivergence()
  const { inspection } = useInspection()

  return (
    <div className="flex flex-col py-6 px-4 w-full max-w-[842px]">
      <Title inspectionType={inspection?.inspectionType} />

      <UserAddress address={inspection?.address} />

      <InspectionCard
        fetched={!!inspection}
        displayDate={inspection?.displayDate}
      />

      <h2 className="mb-6 text-3xl font-bold text-primary">Veja também</h2>

      <div className="flex flex-wrap gap-4 justify-between items-start">
        {inspection?.canRegisterMaintenances && <AlterationCard />}

        <EvaluateCard />

        {inspection?.inspectionType !== 'predial' && !!divergences?.length && (
          <DivergencesCard />
        )}
      </div>
    </div>
  )
}

export default MainPage

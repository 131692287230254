import { ButtonHTMLAttributes } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: 'default' | 'outline' | 'danger'
  colored?: boolean
  children: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  styleType = 'default',
  colored = false,
  type,
  className,
  children,
  ...props
}) => (
  <button
    className={`
      shrink-0
      py-[10px]
      px-5
      shadow-md
      rounded-lg
      hover:opacity-90
      transition-opacity
      cursor-pointer
      disabled:cursor-not-allowed
      disabled:hover:opacity-100
      ${
        styleType === 'outline'
          ? `border-[1px] ${
              colored
                ? 'border-primary text-primary'
                : 'text-gray-400 border-gray-400'
            }`
          : styleType === 'danger'
          ? 'text-white bg-error'
          : 'text-white bg-primary'
      }
      ${className || ''}
    `}
    type={type || 'button'}
    {...props}
  >
    {children}
  </button>
)

export default Button

import X from '../../../assets/svg/X.svg'
import Button from '../../../components/Button'

interface ConfirmDeleteAlterationPopupProps {
  onCancel: () => void
  onConfirmButton: () => void
}

const ConfirmDeleteAlterationPopup: React.FC<
  ConfirmDeleteAlterationPopupProps
> = ({ onCancel, onConfirmButton }) => (
  <div
    onClick={() => onCancel()}
    className="flex fixed inset-0 z-50 justify-center items-center px-4 bg-[#70707059]"
  >
    <div
      className="flex flex-col p-4 px-6 max-w-[460px] bg-white rounded-lg"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="flex justify-between items-center mb-1">
        <span className="text-lg text-gray-700">Tem certeza?</span>

        <img src={X} onClick={() => onCancel()} className="cursor-pointer" />
      </div>

      <hr className="mb-4" />

      <span className="mb-4 text-gray-700">
        Você está prestes a excluir todas as <br /> suas observações referentes
        ao <br />
        reparo deste item.
      </span>

      <div className="flex flex-col gap-y-4">
        <Button styleType="danger" onClick={() => onConfirmButton()}>
          Excluir reparo
        </Button>
        <Button styleType="outline" colored onClick={() => onCancel()}>
          Cancelar
        </Button>
      </div>
    </div>
  </div>
)

export default ConfirmDeleteAlterationPopup

import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { resizePhoto } from '../../infra/files/resizePhoto'
import sendImageToS3andReturnFilepath from '../../infra/http/sendImageToS3'
import { retry } from '../utils/retry'

export type Photo = {
  filePath: string
  hasBeenUploaded?: boolean
}

export type PhotoWithFilePath = Photo & {
  file: File
}

export type Divergence = {
  roomId: string | '0'
  itemId: string | 'building' | 'general'
  description?: string
  photos: PhotoWithFilePath[]
  extra?: any
}

export enum Status {
  ALREADY_SENT = 'ALREADY_SENT',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  EXPIRED = 'EXPIRED'
}

export enum ReviewStatus {
  WAITING_FRANCHISEE_FEEDBACK = 'WAITING_FRANCHISEE_FEEDBACK',
  WAITING_CLIENT_FEEDBACK = 'WAITING_CLIENT_FEEDBACK',
  WAITING_FRANCHISEE_AGREEMENT = 'WAITING_FRANCHISEE_AGREEMENT',
  DONE = 'DONE'
}

export type Report = {
  date: string
  file: string
  hash: string
  tags: any
  type: string
  preview: boolean
  template: string
}

export interface DivergenceContextStore {
  imageUploadUrl?: string
  setImageUploadUrl: Dispatch<React.SetStateAction<string | undefined>>
  divergences: Divergence[]
  setDivergences: Dispatch<React.SetStateAction<Divergence[]>>
  divergenceInProcess?: Divergence
  reports: Report[]
  setReports: Dispatch<React.SetStateAction<Report[]>>
  reviewStatus?: ReviewStatus
  setReviewStatus: Dispatch<React.SetStateAction<ReviewStatus | undefined>>
  status?: Status
  setStatus: Dispatch<React.SetStateAction<Status | undefined>>
  startNewDivergence: (roomId: string, itemId: string) => void
  makeDivergence: (divergence: Divergence) => Promise<void>
  removeDivergencePhotoByFilePath: (filePath: string) => void
  removeDivergence: (roomId: string, itemId: string) => void
  getStoredDivergenceByOrderCode: (
    initialCode: string,
    initialStatus: Status
  ) => void
}

const DivergenceContext = createContext<DivergenceContextStore>(
  {} as DivergenceContextStore
)

DivergenceContext.displayName = 'DivergenceContext'

const divergencesStorageKey = '@rvhotsite:divergences:'

interface DivergenceProviderProps {
  children: React.ReactNode
}

export const DivergenceProvider = ({ children }: DivergenceProviderProps) => {
  const [orderCode, setOrderCode] = useState('')
  const [imageUploadUrl, setImageUploadUrl] = useState<string>()
  const [divergenceInProcess, setDivergenceInProcess] = useState<Divergence>()
  const [divergences, setDivergences] = useState<Divergence[]>([])
  const [reports, setReports] = useState<Report[]>([])
  const [status, setStatus] = useState<Status>()
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>()

  const getStoredDivergenceByOrderCode = useCallback(
    (initialCode: string, initialStatus: Status) => {
      setOrderCode(initialCode)

      const key = divergencesStorageKey + initialCode
      const localStoredData = localStorage.getItem(key)
      const parsedLocalStoredData = JSON.parse(localStoredData || '{}') || null

      if (
        parsedLocalStoredData?.divergences &&
        parsedLocalStoredData?.divergences.length &&
        initialStatus === Status.AVAILABLE
      ) {
        setDivergences((state) => {
          const stateDivergenceIdentifiers = new Set(
            state.map((divergence) => {
              return `${divergence.roomId} - ${divergence.itemId}`
            })
          )
          const mergedDivergences = [
            ...state,
            ...parsedLocalStoredData.divergences.filter(
              (storedDivergence: Divergence) => {
                return !stateDivergenceIdentifiers.has(
                  `${storedDivergence.roomId} - ${storedDivergence.itemId}`
                )
              }
            )
          ]

          return mergedDivergences
        })
      }
    },
    []
  )

  useEffect(() => {
    if (!orderCode || !status) return

    if (status !== Status.AVAILABLE) return

    const key = divergencesStorageKey + orderCode
    const updatedData = { divergences }

    localStorage.setItem(key, JSON.stringify(updatedData))
  }, [divergences, orderCode, status])

  useEffect(() => {
    if (!orderCode || !status) return

    if (status !== Status.AVAILABLE) {
      const key = divergencesStorageKey + orderCode

      localStorage.removeItem(key)
    }
  }, [orderCode, status])

  const startNewDivergence = useCallback(
    (roomId: string, itemId: string) => {
      const alreadyStartedDivergence = divergences.find((divergence) => {
        return divergence?.roomId === roomId && divergence?.itemId === itemId
      })

      if (alreadyStartedDivergence) {
        setDivergenceInProcess(alreadyStartedDivergence)
        return
      }

      setDivergenceInProcess({
        roomId,
        itemId,
        photos: [],
        description: ''
      })
    },
    [divergences]
  )

  const makeDivergence = useCallback(
    async (newDivergence: Divergence) => {
      setDivergenceInProcess(undefined)

      let uploadedPhotos: PhotoWithFilePath[] = []

      if (newDivergence?.photos?.length) {
        uploadedPhotos = await Promise.all(
          newDivergence.photos
            .filter((photo) => !photo?.hasBeenUploaded)
            .map(async (photo) => {
              const { resizedPhoto, url } = await resizePhoto(photo?.file)

              return retry(3, async () => {
                const filePath = await sendImageToS3andReturnFilepath(
                  imageUploadUrl as string,
                  resizedPhoto
                )

                if (url) URL.revokeObjectURL(url)

                return {
                  filePath,
                  file: resizedPhoto,
                  hasBeenUploaded: true
                }
              })
            })
        )
      }

      const successfullyUploadedPhotos = uploadedPhotos.filter((photo) => {
        return !!photo?.filePath
      })

      const divergenceAlreadyExists = divergences.some((existingDivergence) => {
        return (
          existingDivergence?.roomId === newDivergence?.roomId &&
          existingDivergence?.itemId === newDivergence?.itemId
        )
      })

      if (!divergenceAlreadyExists) {
        setDivergences((state) => [
          ...state,
          { ...newDivergence, photos: successfullyUploadedPhotos }
        ])
        return
      }

      setDivergences((state) => {
        return state.map((existingDivergence) => {
          if (
            existingDivergence?.roomId !== newDivergence?.roomId ||
            existingDivergence?.itemId !== newDivergence?.itemId
          ) {
            return existingDivergence
          }

          return {
            ...existingDivergence,
            ...newDivergence,
            photos: [
              ...existingDivergence.photos,
              ...successfullyUploadedPhotos
            ]
          }
        })
      })
    },
    [divergences, imageUploadUrl]
  )

  const removeDivergencePhotoByFilePath = useCallback((filePath: string) => {
    setDivergences((state) => {
      return state.map((divergence) => ({
        ...divergence,
        photos: divergence?.photos.filter((photo) => {
          return photo?.filePath !== filePath
        })
      }))
    })

    setDivergenceInProcess((state) => {
      const divergenceWithoutPhoto = {
        ...state,
        photos: state?.photos.filter((photo) => {
          return photo?.filePath !== filePath
        })
      } as Divergence

      return divergenceWithoutPhoto
    })
  }, [])

  const removeDivergence = useCallback((roomId: string, itemId: string) => {
    setDivergences((state) => {
      const itemToRemove = state.find(
        (obj) => obj.roomId === roomId && obj.itemId === itemId
      )
      return state.filter((divergence) => {
        return divergence !== itemToRemove
      })
    })
  }, [])

  return (
    <DivergenceContext.Provider
      value={{
        imageUploadUrl,
        setImageUploadUrl,
        divergences,
        setDivergences,
        divergenceInProcess,
        startNewDivergence,
        reports,
        setReports,
        status,
        setStatus,
        reviewStatus,
        setReviewStatus,
        makeDivergence,
        removeDivergencePhotoByFilePath,
        removeDivergence,
        getStoredDivergenceByOrderCode
      }}
    >
      {children}
    </DivergenceContext.Provider>
  )
}

export const useDivergence = () => {
  const context = useContext(DivergenceContext)

  if (!context) {
    throw new Error('useDivergence must be used within an DivergenceProvider')
  }

  return context
}

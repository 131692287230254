const { hostname, port } = window.location

const apiBaseUrl = () => {
  switch (hostname) {
    case 'vistori.as':
      return `https://api.redevistorias.com.br`
    case 'sb.vistori.as':
      return `https://api.sandbox.redevistorias.com.br`
    default:
      return `https://api.dev.redevistorias.com.br`
  }
}

const getEnv = () => {
  if (
    hostname.includes('dev.') ||
    hostname.includes('sb.') ||
    hostname.includes('sandbox.') ||
    hostname.includes('localhost')
  ) {
    return 'development'
  }

  return 'production'
}

const config = {
  BASE_URL: `https://${hostname}:${port}`,
  ENV: getEnv(),
  API_BASE_URL: apiBaseUrl(),
  S3_BASE_URL: 'https://s3.amazonaws.com',
  TUTORIAL_VIDEO_URL: 'https://s.vistori.as/assets/mid/CORTE_MID.mp4'
}

export default config

import React from 'react'

import LinearProgress from '@material/react-linear-progress'

import './styles.css'

const ScreenLoading: React.FC = () => (
  <div className="block absolute top-0 left-0 w-screen h-screen bg-white">
    <LinearProgress
      id="screen-loading"
      className="h-2"
      buffer={1}
      progress={1}
      indeterminate
    />
  </div>
)

export default ScreenLoading

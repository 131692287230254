import { SVGProps } from 'react'

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string
}

const CircleInfo: React.FC<IconProps> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.57143 11.4286V6.85715H6.28572V8H7.42857V11.4286H5.71429V12.5714H10.2857V11.4286H8.57143ZM8 3.42857C7.83048 3.42857 7.66476 3.47884 7.5238 3.57303C7.38284 3.66721 7.27298 3.80108 7.20811 3.9577C7.14323 4.11433 7.12626 4.28667 7.15933 4.45294C7.1924 4.61921 7.27404 4.77194 7.39391 4.89181C7.51378 5.01168 7.66651 5.09332 7.83278 5.12639C7.99905 5.15946 8.17139 5.14249 8.32802 5.07761C8.48464 5.01274 8.61851 4.90288 8.71269 4.76192C8.80687 4.62096 8.85715 4.45524 8.85715 4.28572C8.85715 4.05839 8.76684 3.84037 8.60609 3.67963C8.44535 3.51888 8.22733 3.42857 8 3.42857Z"
        fill={props.color || '#0170B3'}
      />
      <path
        d="M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346627 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346627 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16ZM8 1.14286C6.64379 1.14286 5.31803 1.54502 4.19038 2.2985C3.06273 3.05197 2.18383 4.12291 1.66483 5.37589C1.14583 6.62887 1.01003 8.00761 1.27462 9.33776C1.5392 10.6679 2.19228 11.8897 3.15127 12.8487C4.11026 13.8077 5.33209 14.4608 6.66224 14.7254C7.9924 14.99 9.37114 14.8542 10.6241 14.3352C11.8771 13.8162 12.948 12.9373 13.7015 11.8096C14.455 10.682 14.8571 9.35622 14.8571 8C14.8571 6.18138 14.1347 4.43723 12.8487 3.15127C11.5628 1.86531 9.81863 1.14286 8 1.14286Z"
        fill={props.color || '#0170B3'}
      />
    </svg>
  )
}

export default CircleInfo

import { LazyLoadImage } from 'react-lazy-load-image-component'

import MaximizeButton from '../MaximizeButton'

interface ImageItemProps {
  filePath: string
  onMaximizeButtonClick: () => void
}

const ImageItem: React.FC<ImageItemProps> = ({
  filePath,
  onMaximizeButtonClick
}) => (
  <div key={filePath} className="px-2 cursor-pointer">
    <MaximizeButton onClick={onMaximizeButtonClick} />

    <LazyLoadImage
      className="object-cover object-center w-full h-full bg-center bg-no-repeat bg-cover rounded-2xl md:max-h-[600px]"
      src={filePath}
      alt="Foto de item de contestação"
      width="100%"
      effect="blur"
    />
  </div>
)

export default ImageItem
